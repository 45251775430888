import React, { useContext, useEffect, useState } from "react";
import { AuthContext, IntlContext } from "context";
import usePromise from "helpers/usePromise";
import dataFetcher from "dataProvider/OurService";
import { useLocation, useParams } from "react-router";
import { Loader } from "ui/Loader";
import { Link } from "react-router-dom";
import ImageUpload from "components/File/ImageUpload";
import { SubCategory } from "dataProvider/SubCategory";

import AssistanceData from "./Data/Assistance";
import ChildCareData from "./Data/ChildCare";
import GardeningData from "./Data/Gardening";
import HandiworkData from "./Data/Handiwork";
import HouseKeepingData from "./Data/HouseKeeping";
import MovingData from "./Data/Moving";
import TutoringData from "./Data/Tutoring";
import PetCareData from "./Data/PetCare";
import StarRating from "components/Rating/StarRating";
import Button from "ui/Button";
import noteDataProvider from "dataProvider/Note";
import Image from "components/File/Image";

interface LocationState {
    previousPath?: string;
}

const OurServiceView: React.FC = () => {
    const intl = useContext(IntlContext);
    const { id } = useParams();
    const location = useLocation();
    const { account } = useContext(AuthContext);
    const locationState = location.state as LocationState;
    const [displayNotes, setDisplayNotes] = useState<boolean>(false);
    const [dataNotes, setDataNotes] = useState<any>(null);

    const showNotes = () => {
        setDisplayNotes(!displayNotes);
    };

    const { data, isLoading } = usePromise(dataFetcher.one, id);

    useEffect(() => {
        if (data) {
            getNotesData(data);
        }
    }, [data]);

    const getNotesData = async (data: any) => {
        try {
            if (data) {
                const notesProvider = await noteDataProvider.toUser(data.provider.user_id);
                setDataNotes(notesProvider);
            }
        } catch (err: any) {
            throw err;
        }
    };

    if (isLoading || !data) {
        return <Loader />;
    }

    const renderData: React.FC = (subcategory) => {
        switch (subcategory) {
            case SubCategory.TROUBLESHOOTING:
            case SubCategory.REPAIR:
            case SubCategory.ADMINISTRATIVE:
            case SubCategory.INTERNET_USE:
                /*case SubCategory.JOB_SEARCHING:*/
                return <AssistanceData service={data} />;
            case SubCategory.BABYSITTING_DURING_WEEK:
            case SubCategory.BABYSITTING_DURING_WEEKEND:
                return <ChildCareData service={data} />;
            case SubCategory.PLANTATION:
            case SubCategory.WATERING:
            case SubCategory.TERRACE:
            case SubCategory.TRIMMING:
                return <GardeningData service={data} />;
            case SubCategory.SIMPLE:
            case SubCategory.FURNITURE:
            case SubCategory.ELECTRICITY:
            case SubCategory.INSTALLATION:
                return <HandiworkData service={data} />;
            case SubCategory.CLEANING:
            case SubCategory.IRONING:
            case SubCategory.CAR_CLEANING:
            case SubCategory.WINDOW_CLEANING:
            case SubCategory.LAUNDRY:
                return <HouseKeepingData service={data} />;
            case SubCategory.GARAGE:
            case SubCategory.HOUSE:
            case SubCategory.FLAT:
            case SubCategory.SHED:
            case SubCategory.ATTIC:
                return <MovingData service={data} />;
            case SubCategory.PRIVATE_LESSON:
            case SubCategory.HOMEWORK:
            case SubCategory.FOREIGN_LANGUAGE_COURSES:
                return <TutoringData service={data} />;
            case SubCategory.KEEPING_AT_HOME:
            case SubCategory.KEEPING_AT_PETSITTER:
            case SubCategory.WALK:
                return <PetCareData service={data} />;
            default:
                return <></>;
        }
    };

    return (
        <div className="our_services">
            <div className="container view_container">
                <Link
                    to={locationState?.previousPath ?? "/nos-services"}
                    className="btn btn--yellow btn--square margin-bottom--4"
                >
                    {intl.formatMessage("generic.action.back")}
                </Link>
                <div className="our_services_view_container">
                    <div className="provider margin-bottom--5">
                        <ImageUpload
                            id={data?.provider.picture_id}
                            defaultValue={
                                data?.provider.first_name.charAt(0) + " " + data?.provider.last_name.charAt(0) + " "
                            }
                            withUpload={false}
                        />
                        <div>
                            <h3>{data.provider.first_name}</h3>
                            <h4>{data.provider.city}</h4>
                        </div>
                        <StarRating value={data.provider.rating.average} count={data.provider.rating.count} />
                    </div>

                    <div className="margin-bottom--5">
                        {intl.formatMessage("our_services.label.description", {
                            description: data.provider.description,
                        })}
                    </div>

                    <hr />

                    <div className="grid--2 grid--small-1 grid--has-gutter-3x margin-top--5 margin-bottom--5">
                        <div className="service">{renderData(data?.subcategory)}</div>
                        <div className="service_availability">
                            <Link
                                className="btn btn--primary btn--square"
                                to={`/nos-services/${id}/reservation/disponibilites`}
                            >
                                {intl.formatMessage("our_services.label.check_availability")}
                            </Link>
                        </div>
                    </div>

                    {data.other_services.length > 0 && (
                        <React.Fragment>
                            <hr />

                            <div className="grid--2 grid--small-1 grid--has-gutter-3x margin-top--5">
                                <div className="other_services">
                                    <label>{intl.formatMessage("our_services.label.other_services")}</label>

                                    {data.other_services.map((service, key) => {
                                        let translatedSubcategory = intl.formatMessage(`our_services.label.${service}`);

                                        if (key + 1 < data.other_services.length) {
                                            if (key === 0) {
                                                return `${translatedSubcategory}, `;
                                            }
                                            return `${
                                                translatedSubcategory.charAt(0).toLowerCase() +
                                                translatedSubcategory.slice(1)
                                            }, `;
                                        }

                                        return `${
                                            translatedSubcategory.charAt(0).toLowerCase() +
                                            translatedSubcategory.slice(1)
                                        }`;
                                    })}
                                </div>
                                {data.id && account?.resources?.client && account?.resources?.client[0].id && (
                                    <div className="service_contact">
                                        <Link
                                            className="btn btn--primary btn--square"
                                            to={`/messagerie/${data.id}/${account?.resources?.client[0].id}/messages`}
                                        >
                                            {intl.formatMessage("our_services.label.contact")}
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <div className=" margin-top--3 padding-top--3 margin-bottom--3">
                                <Button className="btn btn--primary" onClick={showNotes}>
                                    {displayNotes
                                        ? intl.formatMessage("admin.user.label.provider.notes.hide")
                                        : intl.formatMessage("admin.user.label.provider.notes.show")}
                                </Button>
                            </div>

                            <div className="grid--1 grid--small-1 grid--has-gutter-3x dashboard-form__grid">
                                {displayNotes && (
                                    <div className="grid--3 grid--small-1 grid--has-gutter-3x dashboard-form__grid">
                                        {dataNotes &&
                                            dataNotes.map((note: any) => {
                                                return (
                                                    <div className="note-comment">
                                                        <div className="flex flex--align-items-center margin-bottom--2">
                                                            <Image
                                                                className="note-img margin-right--2"
                                                                id={note.picture_id}
                                                            />
                                                            <h3 className="margin-right--10">{note.first_name}</h3>
                                                            <div className="margin-left--10">
                                                                <StarRating value={note.note} />
                                                            </div>
                                                        </div>
                                                        <div>{note.comment}</div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OurServiceView;

import React, { useContext, useMemo } from "react";
import { AuthContext, IntlContext, FlashContext } from "context";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import dataFetcher from "dataProvider/Profile";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import Button from "ui/Button";
import { TextField, Radio, Amount } from "components/Form/Field";
import { Gender } from "dataProvider/Admin/User";
import Address from "components/Form/Address";
import { Role } from "dataProvider/User";
import { TextView } from "ui/View";

interface Props {
    onEditSubmitSuccess: () => void;
}

const ProfileCompanyEdit: React.FC<Props> = ({ onEditSubmitSuccess }) => {
    const intl = useContext(IntlContext);
    const { account } = useContext(AuthContext);
    const flashMessage = useContext(FlashContext);

    const onSubmit = async (values: any) => {
        try {
            await dataFetcher.update(account!.user.id, values);
            flashMessage?.addSuccess(intl.formatMessage("profile.company.data.update"));
            onEditSubmitSuccess();
        } catch (err: any) {
            if (err.response && err.response.data) {
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    const initialValues = useMemo(() => {
        return {
            gender: account?.user.gender,
            last_name: account?.user.last_name,
            first_name: account?.user.first_name,
            phone: account?.user.phone,
            address: account?.user.address,
            email: account?.user.email,
            resources: {
                company: {
                    name: account?.resources?.company[0]?.name,
                    code: account?.resources?.company[0].code,
                    siren: account?.resources?.company[0].siren,
                    limit: account?.resources?.company[0].limit,
                    nb_employees: account?.resources?.company[0].nb_employees,
                    function: account?.resources?.company[0].function,
                    percentage: account?.resources?.company[0].percentage,
                    signed_date: account?.resources?.company[0].signed_date,
                },
            },
        };
    }, [account]);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} className="register__form">
                    <div className="register__group-fields">
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <TextView
                                label={intl.formatMessage("profile.company.label.signed_date")}
                                value={account?.resources?.company[0].signed_date}
                            />
                            <TextField
                                inputProps={{
                                    name: "resources.company.name",
                                    required: true,
                                    maxLength: 255,
                                    placeholder: intl.formatMessage("profile.label.social_reason"),
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.social_reason"),
                                }}
                            />
                            <TextField
                                inputProps={{
                                    name: "resources.company.siren",
                                    required: true,
                                    minLength: 9,
                                    maxLength: 9,
                                    placeholder: intl.formatMessage("profile.label.siren"),
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.siren"),
                                }}
                            />
                            <Amount
                                inputProps={{
                                    name: "resources.company.percentage",
                                    required: true,
                                    placeholder: intl.formatMessage("profile.label.company_contribution"),
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.company_contribution"),
                                }}
                            />
                            <TextField
                                inputProps={{
                                    name: "resources.company.nb_employees",
                                    placeholder: intl.formatMessage("profile.label.nb_employees"),
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.nb_employees"),
                                }}
                            />
                            <Amount
                                inputProps={{
                                    name: "resources.company.limit",
                                    required: true,
                                    placeholder: intl.formatMessage("profile.label.maximum_authorized_limit"),
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.maximum_authorized_limit"),
                                }}
                            />
                            <TextView
                                label={intl.formatMessage("profile.label.company_code")}
                                value={account?.resources?.company[0].code || ""}
                            />
                        </div>
                    </div>
                    <div className="register__group-fields">
                        <div className="register__grid">
                            <Radio
                                fieldControlProps={{
                                    className: "field-control-radio field__row--inline",
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.gender"),
                                }}
                                inputProps={{
                                    name: "gender",
                                    required: true,
                                    options: [
                                        {
                                            value: Gender.FEMALE,
                                            label: intl.formatMessage("profile.label.gender.female"),
                                        },
                                        {
                                            value: Gender.MALE,
                                            label: intl.formatMessage("profile.label.gender.male"),
                                        },
                                    ],
                                }}
                            />
                        </div>
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <TextField
                                inputProps={{
                                    name: "last_name",
                                    required: true,
                                    maxLength: 255,
                                    placeholder: intl.formatMessage("profile.label.last_name"),
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.last_name"),
                                }}
                            />
                            <TextField
                                inputProps={{
                                    name: "first_name",
                                    required: true,
                                    maxLength: 255,
                                    placeholder: intl.formatMessage("profile.label.first_name"),
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.first_name"),
                                }}
                            />
                            <TextField
                                inputProps={{
                                    name: "resources.company.function",
                                    maxLength: 255,
                                    placeholder: intl.formatMessage("profile.label.function"),
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.function"),
                                    required: true,
                                }}
                            />
                            <TextField
                                inputProps={{
                                    name: "phone",
                                    required: true,
                                    maxLength: 10,
                                    placeholder: intl.formatMessage("profile.label.phone"),
                                }}
                                labelProps={{
                                    label: intl.formatMessage("profile.label.phone"),
                                }}
                            />
                        </div>
                    </div>
                    <div className="register__group-fields">
                        <Address
                            inputProps={{
                                name: "address",
                                latitudeName: "coordinates_lat",
                                longitudeName: "coordinates_lon",
                                houseNumber: "house_number",
                                street: "street",
                                postcode: "postcode",
                                city: "city",
                                required: true,
                            }}
                            labelProps={{
                                label: account?.user.roles?.includes(Role.COMPANY)
                                    ? intl.formatMessage("profile.label.company_address")
                                    : intl.formatMessage("profile.label.address"),
                            }}
                        />
                        <TextField
                            inputProps={{
                                name: "email",
                                required: true,
                                placeholder: intl.formatMessage("profile.label.email"),
                            }}
                            labelProps={{
                                label: intl.formatMessage("profile.label.email"),
                            }}
                        />
                    </div>

                    <div className="text-center margin-top--3 padding-top--3">
                        <Button className="btn btn__outline--primary" type="submit" disabled={submitting}>
                            {intl.formatMessage("generic.action.save")}
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

export default ProfileCompanyEdit;

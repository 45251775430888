import { Category } from "./Category";
import { SubCategory } from "./SubCategory";
import fetch, { parseResponse, parseListResponse, parseFileResponse } from "./fetch";
import { NoteInterface } from "dataProvider/Note";
import { BookingValidationInterface } from "dataProvider/BookingValidation";
export enum BookingStatus {
    PENDING = "PENDING",
    IN_PROGRESS = "IN_PROGRESS",
    TO_VALIDATE = "TO_VALIDATE",
    CLOSED = "CLOSED",
}

export interface BookingUserInterface {
    last_name: string;
    first_name: string;
    address: string;
    city: string;
    photo: string;
    description?: string;
    user_id: string;
}

export interface BookingInterface {
    id: string;
    code: string;
    service_id: string;
    client_id: string;
    client_address: string;
    provider_id: string;
    category: Category;
    subcategory: SubCategory;
    price: string;
    date: string;
    start_time: string;
    nb_hours: number;
    data: any;
    status: BookingStatus;
    client: BookingUserInterface;
    provider: BookingUserInterface;
    invoices_count: number;
    notes: { client: NoteInterface; provider: NoteInterface };
    booking_validation: { client: BookingValidationInterface; provider: BookingValidationInterface };
    amount: string;
    supported_company?: string;
    created_at: object;
    updated_at: object;
}

class Booking {
    all(params?: HashMap<any>) {
        return parseListResponse<any>(fetch.get(`booking`, { params }));
    }

    one(id: string, params?: HashMap<any>) {
        return parseResponse<BookingInterface>(fetch.get(`booking/${id}`, { params }));
    }

    cancel(id: string) {
        return parseResponse<BookingInterface>(fetch.post(`booking/${id}/cancel`));
    }

    downloadSalesSuccession({ booking_id }: { booking_id: string }) {
        return parseFileResponse(fetch.get(`booking/${booking_id}/download_sales_succession`));
    }
}

export default new Booking();

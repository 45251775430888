import React, { useContext } from "react";
import CollaboratorCommonEdit from "./Common/Edit";
import { AuthContext, IntlContext } from "context";
import Button from "ui/Button";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { Navigate } from "react-router";

interface Props {
    onEditSubmitSuccess: () => void;
    onClickHandleMode: () => void;
}

const Edit: React.FC<Props> = ({ onEditSubmitSuccess, onClickHandleMode }) => {
    const intl = useContext(IntlContext);
    const auth = useContext(AuthContext);

    if (auth.currentRole === "company") {
        return (
            <div className="register__container">
                <div className="register__container">
                    <div className="flex flex--justify-content-flex-end">
                        <Button className="btn btn--primary" onClick={onClickHandleMode}>
                            <Icon icon={{ prefix: "fas", iconName: "pencil" }} className="icon--left" />
                            {intl.formatMessage("generic.action.cancel")}
                        </Button>
                    </div>
                    <CollaboratorCommonEdit onEditSubmitSuccess={onEditSubmitSuccess} />
                </div>
            </div>
        );
    }

    return <Navigate to="/" />;
};

export default Edit;

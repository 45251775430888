import React, { useContext, useMemo, useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { AuthContext, IntlContext } from "context";
import { Form } from "react-final-form";
import Button from "ui/Button";
import arrayMutators from "final-form-arrays";
import { useParams } from "react-router";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import { BookingContext } from "../Context";
import dataFetcher from "dataProvider/BookService";
import InfoService from "./InfoService";
import roundNumber from "helpers/roundNumber";
import CalculOpperator from "helpers/CalculOpperator";

interface Props {
    paymentIntent: string;
}

const StripePaymentForm: React.FC<Props> = ({ paymentIntent }) => {
    const stripe = useStripe();
    const elements = useElements();
    const intl = useContext(IntlContext);
    const { account } = useContext(AuthContext);
    const [error, setError] = useState<string | undefined>();
    const { id } = useParams();
    const { getBooking } = useContext(BookingContext);
    const bookingData = useMemo(() => {
        return getBooking();
    }, [getBooking]);

    const onSubmit = async (data: any) => {
        if (!stripe || !elements) {
            // Stripe.js n'a pas encore été chargé.
            // Assurez-vous de l'importer correctement dans votre application.
            return;
        }

        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/nos-services/${id}/reservation/confirmation`,
                },
                redirect: "if_required",
            });

            if (error) {
                if (error.type === "card_error" || error.type === "validation_error") {
                    setError(error.message);
                }
            } else if (paymentIntent && paymentIntent.status === "requires_capture") {
                try {
                    if (bookingData) {
                        const totalPrice = parseFloat(bookingData.price) * bookingData.nb_hours;
                        const opperator = new CalculOpperator(data);
                        let [, totalAmount] = opperator.computeTTC(totalPrice);
                        let supportedCompany = 0;

                        if (account?.resources?.client && account?.resources?.client[0].company_percentage) {
                            supportedCompany =
                                totalAmount * (parseFloat(account?.resources?.client[0].company_percentage) / 100);
                        }

                        totalAmount = totalAmount - supportedCompany;

                        await dataFetcher.createConfirmPayment({
                            booking_id: bookingData?.id,
                            provider_id: bookingData?.provider_id,
                            client_id: bookingData?.client_id,
                            payment_intent: paymentIntent.id,
                            amount: roundNumber(totalAmount) * 100,
                        });
                        window.location.href = `${window.location.origin}/nos-services/${id}/reservation/confirmation`;
                    }
                } catch (err: any) {
                    console.log(err);
                }
            }
        } catch (err: any) {
            if (err.response && err.response.data) {
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{}}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="login__form">
                    <div className="our_services_view_container">
                        <div className="grid--1 grid--medium-1 grid--small-1 grid--has-gutter-3x">
                            <div className="container_form_intermediate_page">
                                <div className="container_form_intermediate_page padding--3">
                                    <InfoService />
                                </div>
                            </div>
                        </div>
                        <div className="grid--12 grid--medium-12 grid--small-12 grid--has-gutter-3x padding-top--3">
                            <div className="container_form_intermediate_page padding--3 grid__col--6 grid__col--small-12">
                                <h2 className="h2 margin-bottom--3">
                                    {intl.formatMessage("our_services.form.label.title")}
                                </h2>
                                <PaymentElement />
                                {error && <div>{error}</div>}
                            </div>
                        </div>
                        <div className="flex flex--align-items-center flex--justify-content-center margin-top--2">
                            <Button type="submit" className="btn btn--primary">
                                {intl.formatMessage("our_services.form.action.pay")}
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        />
    );
};

export default StripePaymentForm;

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dataFetcher from "dataProvider/OurService";
import { IntlContext, ListContext } from "context";
import { FilterForm, ListItemWithIds, ListProvider, ListWrapper } from "components/List";
import Filters from "./Filters";
import { LinkSubCategoryWithCategory, SubCategory } from "dataProvider/SubCategory";
import SelectSort from "app/Booking/List/SelectSort";
import { ServiceInterface } from "dataProvider/Service";
import Image from "components/File/Image";
import StarRating from "components/Rating/StarRating";
import useQueryString from "helpers/useQueryString";

const ComponentList: React.FC = () => {
    const intl = useContext(IntlContext);

    return (
        <ListItemWithIds
            grid={12}
            has_gutter={3}
            classContainerItem={"our_services_container grid__col--4 grid__col--medium-6 grid__col--small-12"}
            rendererHeader={(service: ServiceInterface) => {
                const previousPath = window.location.pathname + window.location.search;
                const subCategory: SubCategory = service.subcategory;
                const price = Number.parseFloat(service.price).toPrecision(4);

                return (
                    <div key={`heading-${service.id}`} className="items">
                        <div className="service_content">
                            <Link to={`/nos-services/${service.id}`} state={{ previousPath }}>
                                <div className="grid--2">
                                    <div className="items--image">
                                        {service.provider.picture_id ? (
                                            <Image id={service.provider.picture_id} className="image" />
                                        ) : (
                                            <div className="container_initial_provider">
                                                <span>
                                                    {service?.provider.last_name.charAt(0)}{" "}
                                                    {service?.provider.first_name.charAt(0)}{" "}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <StarRating
                                        value={service.provider.rating.average}
                                        count={service.provider.rating.count}
                                    />
                                </div>

                                <div className="items--info_provider margin-top--3">
                                    <div className="items--provider">
                                        <span className="provider--name">
                                            {service.provider.first_name + " " + service.provider.last_name}
                                        </span>
                                        <span className="provider--address">{service.provider.city}</span>
                                    </div>
                                    <div className="items--price">
                                        {intl.formatMessage("our_services.list.label.price", {
                                            price: price,
                                        })}
                                    </div>
                                </div>

                                <div className="items--provider_description margin-top--3">
                                    {service.provider.description}
                                </div>

                                <div className="item--subcategory margin-top--3">
                                    {intl.formatMessage(
                                        "subcategory." +
                                            LinkSubCategoryWithCategory[subCategory].toLocaleLowerCase() +
                                            "." +
                                            service.subcategory.toLocaleLowerCase()
                                    )}
                                </div>
                            </Link>
                        </div>
                        <div className="items--links margin-top--3">
                            <Link
                                className="btn btn__outline--secondary"
                                to={`/nos-services/${service.id}/reservation/disponibilites`}
                                state={{ previousPath }}
                            >
                                {intl.formatMessage("our_services.list.label.show_availabilities")}
                            </Link>
                        </div>
                    </div>
                );
            }}
        >
            {[]}
        </ListItemWithIds>
    );
};

const OutServicesList: React.FC = () => {
    const intl = useContext(IntlContext);
    const { data } = useContext(ListContext);
    const queryString = useQueryString();
    const [noResultsLabel, setNoResultsLabel] = useState<string>(intl.formatMessage("generic.crud.list.empty"));

    useEffect(() => {
        if (
            !!(
                data?.active_filters?.filter_date ||
                data?.active_filters?.filter_hour ||
                queryString.values?.filter_date ||
                queryString.values?.filter_hour
            ) &&
            !!(!data.pager.total || data.pager.total === 0)
        ) {
            setNoResultsLabel(intl.formatMessage("our_services.list.label.no_data_with_filter_date_hour"));
        } else {
            setNoResultsLabel(intl.formatMessage("generic.crud.list.empty"));
        }
    }, [data.active_filters, data.pager.total, intl, queryString.values]);

    return (
        <React.Fragment>
            <div className="filters">
                <FilterForm withButtonSubmit={false}>
                    <Filters defaultFilters={{ filter_price: data.pager?.custom?.max }} />
                </FilterForm>
            </div>

            <div className="our_services--sort margin-bottom--3">
                <SelectSort
                    sortOptions={[
                        {
                            field: "price",
                            order: "asc",
                            label: intl.formatMessage("our_services.order.price.asc"),
                        },
                        {
                            field: "price",
                            order: "desc",
                            label: intl.formatMessage("our_services.order.price.desc"),
                        },
                        {
                            field: "created_at",
                            order: "desc",
                            label: intl.formatMessage("our_services.order.date.desc"),
                        },
                        {
                            field: "created_at",
                            order: "asc",
                            label: intl.formatMessage("our_services.order.date.asc"),
                        },
                        {
                            field: "rating",
                            order: "desc",
                            label: intl.formatMessage("our_services.order.rating.asc"),
                        },
                        {
                            field: "provider_created_at",
                            order: "asc",
                            label: intl.formatMessage("our_services.order.provider.asc"),
                        },
                    ]}
                    withIconSort={true}
                />
            </div>
            <ListWrapper noResultsLabel={noResultsLabel}>
                <ComponentList />
            </ListWrapper>
        </React.Fragment>
    );
};

const Wrapper: React.FC = () => {
    const defaultFetcherParams = {
        order: "created_at",
        direction: "desc",
    };

    return (
        <div className="container our_services">
            <ListProvider dataFetcher={dataFetcher.all} defaultFetcherParams={defaultFetcherParams}>
                <OutServicesList />
            </ListProvider>
        </div>
    );
};

export default Wrapper;

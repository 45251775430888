import React, { useContext } from "react";
import { IntlContext } from "context";
import { SubCategory } from "dataProvider/SubCategory";
import { ServiceInterface } from "dataProvider/Service";

interface Props {
    service: ServiceInterface;
}

const Gardening: React.FC<Props> = ({ service }) => {
    const intl = useContext(IntlContext);

    return (
        <React.Fragment>
            {service.subcategory === SubCategory.WATERING && (
                <div className="service_detail">
                    <label>{intl.formatMessage("service.label.watering_place")}</label>
                    {service.data.watering_place
                        ? service.data.watering_place
                        : intl.formatMessage("service.label.watering_place.undefined")}
                </div>
            )}

            {service.subcategory !== SubCategory.WATERING && (
                <div className="service_detail">
                    <label>{intl.formatMessage("our_services.label.bring_products")}</label>
                    {service.data.bring_products
                        ? intl.formatMessage("generic.label.yes")
                        : intl.formatMessage("generic.label.no")}
                </div>
            )}

            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.${service.subcategory}`)}</label>
                {intl.formatMessage("our_services.label.effective_price", {
                    price: Number.parseFloat(service.price).toPrecision(4),
                })}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.accepted_payment_type`)}</label>
                Carte bancaire, Césu {/* TODO: get payment from server */}
            </div>
        </React.Fragment>
    );
};

export default Gardening;

import React, { useContext, useState } from "react";
import { useFormState } from "react-final-form";
import { IntlContext } from "context";
import TextareaField from "components/Form/Field/Textarea";
import Label from "ui/Form/Element/Label";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import Modal from "ui/Modal";
import { Checkbox } from "components/Form/Field";
import { LinkSubCategoryWithCategory, SubCategory } from "dataProvider/SubCategory";
import Button from "ui/Button";

interface Props {
    onClickHandleMode: () => void;
}

const FormFields: React.FC<Props> = ({ onClickHandleMode }) => {
    const intl = useContext(IntlContext);
    const { values } = useFormState();
    const [hiddenModal, setHiddenModal] = useState<boolean>(true);

    return (
        <React.Fragment>
            <TextareaField
                inputProps={{
                    name: "resources.provider.description",
                }}
                labelProps={{
                    label: (
                        <div className="my_profile_description_label_container">
                            <span className="margin-right--2">
                                {intl.formatMessage("profile.label.my_profile.description")}
                                <Button
                                    className="btn btn__icon btn__icon--circle margin-left--2"
                                    onClick={onClickHandleMode}
                                >
                                    <Icon icon={{ prefix: "fas", iconName: "close" }} className="icon" />
                                </Button>
                            </span>
                        </div>
                    ),
                }}
            />
            <div className="my_profile_services margin-top--2">
                <Label>{intl.formatMessage("profile.label.my_profile.services")}</Label>
                {values?.resources?.provider.services &&
                    values?.resources?.provider.services.map((service: SubCategory) => {
                        return (
                            <div
                                className="btn btn__outline--primary btn__outline--square  btn--default my_profile_services--items"
                                key={service}
                            >
                                {intl.formatMessage(
                                    "subcategory." +
                                        LinkSubCategoryWithCategory[service].toLocaleLowerCase() +
                                        "." +
                                        service.toLocaleLowerCase()
                                )}
                            </div>
                        );
                    })}
                <div
                    className="btn btn__outline--primary btn__outline--square  btn--default my_profile_services--items"
                    onClick={() => setHiddenModal(false)}
                >
                    <Icon icon={{ prefix: "fas", iconName: "plus" }} />
                </div>
            </div>
            <Modal
                onClose={() => setHiddenModal(true)}
                isHidden={hiddenModal}
                title={intl.formatMessage("profile.modal.title")}
                withHeight80vh={true}
            >
                <p className="h3 margin-bottom--2">
                    {intl.formatMessage("authentication.register.label.choice_services_interest_you")}
                </p>

                <p className="h3 margin-bottom--2">{intl.formatMessage("category.housekeeping")}</p>
                <div className="register__group-fields">
                    <div className="register__grid">
                        <Checkbox
                            fieldControlProps={{
                                className: "services__group",
                            }}
                            inputProps={{
                                name: "resources.provider.services",
                                className: "services__input-checkbox",
                                groupClassName: "services__input-group",
                                labelClassName: "services__input-label",
                                options: [
                                    {
                                        value: SubCategory.CLEANING,
                                        label: intl.formatMessage("subcategory.housekeeping.cleaning"),
                                    },
                                    {
                                        value: SubCategory.IRONING,
                                        label: intl.formatMessage("subcategory.housekeeping.ironing"),
                                    },
                                    {
                                        value: SubCategory.CAR_CLEANING,
                                        label: intl.formatMessage("subcategory.housekeeping.car_cleaning"),
                                    },
                                    {
                                        value: SubCategory.WINDOW_CLEANING,
                                        label: intl.formatMessage("subcategory.housekeeping.window_cleaning"),
                                    },
                                ],
                                maxCheckedValue: 3,
                            }}
                        />
                    </div>
                </div>

                <p className="h3 margin-bottom--2">{intl.formatMessage("category.childcare")}</p>
                <div className="register__group-fields">
                    <div className="register__grid">
                        <Checkbox
                            fieldControlProps={{
                                className: "services__group",
                            }}
                            inputProps={{
                                name: "resources.provider.services",
                                className: "services__input-checkbox",
                                groupClassName: "services__input-group",
                                labelClassName: "services__input-label",
                                options: [
                                    {
                                        value: SubCategory.BABYSITTING_DURING_WEEK,
                                        label: intl.formatMessage("subcategory.childcare.babysitting_during_week"),
                                    },
                                    {
                                        value: SubCategory.BABYSITTING_DURING_WEEKEND,
                                        label: intl.formatMessage("subcategory.childcare.babysitting_during_weekend"),
                                    },
                                ],
                                maxCheckedValue: 3,
                            }}
                        />
                    </div>
                </div>

                <p className="h3 margin-bottom--2">{intl.formatMessage("category.gardening")}</p>
                <div className="register__group-fields">
                    <div className="register__grid">
                        <Checkbox
                            fieldControlProps={{
                                className: "services__group",
                            }}
                            inputProps={{
                                name: "resources.provider.services",
                                className: "services__input-checkbox",
                                groupClassName: "services__input-group",
                                labelClassName: "services__input-label",
                                options: [
                                    {
                                        value: SubCategory.PLANTATION,
                                        label: intl.formatMessage("subcategory.gardening.plantation"),
                                    },
                                    {
                                        value: SubCategory.WATERING,
                                        label: intl.formatMessage("subcategory.gardening.watering"),
                                    },
                                    {
                                        value: SubCategory.TERRACE,
                                        label: intl.formatMessage("subcategory.gardening.terrace"),
                                    },
                                    {
                                        value: SubCategory.TRIMMING,
                                        label: intl.formatMessage("subcategory.gardening.trimming"),
                                    },
                                ],
                                maxCheckedValue: 3,
                            }}
                        />
                    </div>
                </div>

                <p className="h3 margin-bottom--2">{intl.formatMessage("category.handiwork")}</p>
                <div className="register__group-fields">
                    <div className="register__grid">
                        <Checkbox
                            fieldControlProps={{
                                className: "services__group",
                            }}
                            inputProps={{
                                name: "resources.provider.services",
                                className: "services__input-checkbox",
                                groupClassName: "services__input-group",
                                labelClassName: "services__input-label",
                                options: [
                                    {
                                        value: SubCategory.SIMPLE,
                                        label: intl.formatMessage("subcategory.handiwork.simple"),
                                    },
                                    {
                                        value: SubCategory.FURNITURE,
                                        label: intl.formatMessage("subcategory.handiwork.furniture"),
                                    },
                                    {
                                        value: SubCategory.ELECTRICITY,
                                        label: intl.formatMessage("subcategory.handiwork.electricity"),
                                    },
                                    {
                                        value: SubCategory.INSTALLATION,
                                        label: intl.formatMessage("subcategory.handiwork.installation"),
                                    },
                                ],
                                maxCheckedValue: 3,
                            }}
                        />
                    </div>
                </div>

                <p className="h3 margin-bottom--2">{intl.formatMessage("category.moving")}</p>
                <div className="register__group-fields">
                    <div className="register__grid">
                        <Checkbox
                            fieldControlProps={{
                                className: "services__group",
                            }}
                            inputProps={{
                                name: "resources.provider.services",
                                className: "services__input-checkbox",
                                groupClassName: "services__input-group",
                                labelClassName: "services__input-label",
                                options: [
                                    {
                                        value: SubCategory.GARAGE,
                                        label: intl.formatMessage("subcategory.moving.garage"),
                                    },
                                    {
                                        value: SubCategory.HOUSE,
                                        label: intl.formatMessage("subcategory.moving.house"),
                                    },
                                    {
                                        value: SubCategory.FLAT,
                                        label: intl.formatMessage("subcategory.moving.flat"),
                                    },
                                    {
                                        value: SubCategory.SHED,
                                        label: intl.formatMessage("subcategory.moving.shed"),
                                    },
                                    {
                                        value: SubCategory.ATTIC,
                                        label: intl.formatMessage("subcategory.moving.attic"),
                                    },
                                ],
                                maxCheckedValue: 3,
                            }}
                        />
                    </div>
                </div>

                <p className="h3 margin-bottom--2">{intl.formatMessage("category.tutoring")}</p>
                <div className="register__group-fields">
                    <div className="register__grid">
                        <Checkbox
                            fieldControlProps={{
                                className: "services__group",
                            }}
                            inputProps={{
                                name: "resources.provider.services",
                                className: "services__input-checkbox",
                                groupClassName: "services__input-group",
                                labelClassName: "services__input-label",
                                options: [
                                    {
                                        value: SubCategory.PRIVATE_LESSON,
                                        label: intl.formatMessage("subcategory.tutoring.private_lesson"),
                                    },
                                    {
                                        value: SubCategory.HOMEWORK,
                                        label: intl.formatMessage("subcategory.tutoring.homework"),
                                    },
                                    {
                                        value: SubCategory.FOREIGN_LANGUAGE_COURSES,
                                        label: intl.formatMessage("subcategory.tutoring.foreign_language_courses"),
                                    },
                                ],
                                maxCheckedValue: 3,
                            }}
                        />
                    </div>
                </div>

                <p className="h3 margin-bottom--2">{intl.formatMessage("category.petcare")}</p>
                <div className="register__group-fields">
                    <div className="register__grid">
                        <Checkbox
                            fieldControlProps={{
                                className: "services__group",
                            }}
                            inputProps={{
                                name: "resources.provider.services",
                                className: "services__input-checkbox",
                                groupClassName: "services__input-group",
                                labelClassName: "services__input-label",
                                options: [
                                    {
                                        value: SubCategory.KEEPING_AT_HOME,
                                        label: intl.formatMessage("subcategory.petcare.keeping_at_home"),
                                    },
                                    {
                                        value: SubCategory.KEEPING_AT_PETSITTER,
                                        label: intl.formatMessage("subcategory.petcare.keeping_at_petsitter"),
                                    },
                                    {
                                        value: SubCategory.WALK,
                                        label: intl.formatMessage("subcategory.petcare.walk"),
                                    },
                                ],
                                maxCheckedValue: 3,
                            }}
                        />
                    </div>
                </div>

                <p className="h3 margin-bottom--2">{intl.formatMessage("category.assistance")}</p>
                <div className="register__group-fields">
                    <div className="register__grid">
                        <Checkbox
                            fieldControlProps={{
                                className: "services__group",
                            }}
                            inputProps={{
                                name: "resources.provider.services",
                                className: "services__input-checkbox",
                                groupClassName: "services__input-group",
                                labelClassName: "services__input-label",
                                options: [
                                    {
                                        value: SubCategory.TROUBLESHOOTING,
                                        label: intl.formatMessage("subcategory.assistance.troubleshooting"),
                                    },
                                    {
                                        value: SubCategory.REPAIR,
                                        label: intl.formatMessage("subcategory.assistance.repair"),
                                    },
                                    {
                                        value: SubCategory.INTERNET_USE,
                                        label: intl.formatMessage("subcategory.assistance.internet_use"),
                                    },
                                    {
                                        value: SubCategory.ADMINISTRATIVE,
                                        label: intl.formatMessage("subcategory.assistance.administrative"),
                                    },
                                    /*{
                                        value: SubCategory.JOB_SEARCHING,
                                        label: intl.formatMessage("subcategory.assistance.job_searching"),
                                    },*/
                                ],
                                maxCheckedValue: 3,
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default FormFields;

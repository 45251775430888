import React, { useContext } from "react";
import { AuthContext, IntlContext } from "context";
import { TextView } from "ui/View";
import Label from "ui/Form/Element/Label";
import { LinkSubCategoryWithCategory, SubCategory } from "dataProvider/SubCategory";
import Button from "ui/Button";

interface Props {
    onClickHandleMode: () => void;
}

const View: React.FC<Props> = ({ onClickHandleMode }) => {
    const { account } = useContext(AuthContext);
    const intl = useContext(IntlContext);

    return (
        <div className="register__group-fields margin-top--5">
            <div className="register__grid">
                <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextView
                        label={
                            <div className="my_profile_description_label_container">
                                <span className="margin-right--2">
                                    {intl.formatMessage("profile.label.my_profile.description")}
                                    <Button
                                        className="btn btn__icon btn__icon--circle margin-left--2"
                                        onClick={onClickHandleMode}
                                    >
                                        <span className="icon icon-pen"></span>
                                    </Button>
                                </span>
                            </div>
                        }
                        value={account?.resources?.provider[0].description}
                        inputClassname={account?.resources?.provider[0].description ? "my_profile_description" : ""}
                    />
                    <div className="my_profile_services margin-top--2">
                        <Label>{intl.formatMessage("profile.label.my_profile.services")}</Label>
                        {account?.resources?.provider[0].services.map((service: any) => {
                            let serviceSubCategory: SubCategory = service;
                            return (
                                <div
                                    className="btn btn__outline--primary btn__outline--square  btn--default my_profile_services--items"
                                    key={service}
                                >
                                    {intl.formatMessage(
                                        "subcategory." +
                                            LinkSubCategoryWithCategory[serviceSubCategory].toLocaleLowerCase() +
                                            "." +
                                            service.toLocaleLowerCase()
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default View;

import React, { useCallback, useState, useEffect, PropsWithChildren } from "react";
import { useIntl } from "react-intl";
import DeviceService, { DeviceInterface } from "dataProvider/Reference/Device";
import PetTypeService, { PetTypeInterface } from "dataProvider/Reference/PetType";
import ScholarLevelService, { ScholarLevelInterface } from "dataProvider/Reference/ScholarLevel";
import ScholarSubjectService, { ScholarSubjectInterface } from "dataProvider/Reference/ScholarSubject";
import SurfaceTypeService, { SurfaceTypeInterface } from "dataProvider/Reference/SurfaceType";
import MovingVehicleTypeService, { MovingVehicleTypeInterface } from "dataProvider/Reference/MovingVehicleType";
import VehicleTypeService, { VehicleTypeInterface } from "dataProvider/Reference/VehicleType";
import FileService, { FileInterface } from "dataProvider/Reference/File";
import MobileLinkService, { MobileLinkInterface } from "dataProvider/Reference/MobileLink";
import { Loader } from "ui/Loader";
import ReportingService, { ReportingInterface } from "dataProvider/Reference/Reporting";
import ForeignLanguages, { ForeignLanguagesInterface } from "dataProvider/Reference/ForeignLanguages";

interface Options {
    value: string | number;
    label: string;
}

export interface getReferenceReturn {
    device: DeviceInterface;
    pet_type: PetTypeInterface;
    scholar_level: ScholarLevelInterface;
    scholar_subject: ScholarSubjectInterface;
    surface_type: SurfaceTypeInterface;
    moving_vehicle_type: MovingVehicleTypeInterface;
    vehicle_type: VehicleTypeInterface;
    file: FileInterface;
    reporting: ReportingInterface;
    mobile_link: MobileLinkInterface;
    foreign_languages: ForeignLanguagesInterface;
}

export interface ReferencesProviderInterface {
    all<T extends keyof getReferenceReturn>(type: T): Array<getReferenceReturn[T]>;
    selectList<T extends keyof getReferenceReturn>(type: T): Array<Options>;
    get<T extends keyof getReferenceReturn>(type: T, code: string): getReferenceReturn[T] | undefined;
    refreshData<T extends keyof getReferenceReturn>(type?: T): void;
}

type BaseReferencesStateInterface = {
    [Key in keyof getReferenceReturn]: Array<getReferenceReturn[Key]>;
};

const ReferenceContext = React.createContext<ReferencesProviderInterface>({
    all: () => [],
    get: () => undefined,
    selectList: () => [],
    refreshData: () => null,
});

const Consumer = ReferenceContext.Consumer;

const Provider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const { locale } = useIntl();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [references, setReferences] = useState<BaseReferencesStateInterface>({
        device: [],
        pet_type: [],
        scholar_level: [],
        scholar_subject: [],
        surface_type: [],
        moving_vehicle_type: [],
        vehicle_type: [],
        file: [],
        reporting: [],
        mobile_link: [],
        foreign_languages: [],
    });

    const fetchReferences = useCallback(async (languageCode: string, type?: string) => {
        switch (type) {
            case "device":
                const devices = await DeviceService.all(languageCode);
                setReferences(Object.assign({}, references, { device: devices }));
                break;
            case "pet_type":
                const petTypes = await PetTypeService.all(languageCode);
                setReferences(Object.assign({}, references, { petType: petTypes }));
                break;
            case "scholar_level":
                const scholarLevels = await ScholarLevelService.all(languageCode);
                setReferences(Object.assign({}, references, { scholarLevel: scholarLevels }));
                break;
            case "scholar_subject":
                const scholarSubjects = await ScholarSubjectService.all(languageCode);
                setReferences(Object.assign({}, references, { scholarSubject: scholarSubjects }));
                break;
            case "surface_type":
                const surfaceType = await SurfaceTypeService.all(languageCode);
                setReferences(Object.assign({}, references, { scholarSubject: surfaceType }));
                break;
            case "moving_vehicle_type":
                const movingVehicleType = await MovingVehicleTypeService.all(languageCode);
                setReferences(Object.assign({}, references, { scholarSubject: movingVehicleType }));
                break;
            case "vehicle_type":
                const vehicleType = await VehicleTypeService.all(languageCode);
                setReferences(Object.assign({}, references, { scholarSubject: vehicleType }));
                break;
            case "file":
                const file = await FileService.all(languageCode);
                setReferences(Object.assign({}, references, { file: file }));
                break;
            case "reporting":
                const reporting = await ReportingService.all(languageCode);
                setReferences(Object.assign({}, references, { scholarSubject: reporting }));
                break;
            case "mobile_link":
                const link = await ReportingService.all(languageCode);
                setReferences(Object.assign({}, references, { scholarSubject: link }));
                break;
            case "foreign_languages":
                const foreignLanguages = await ReportingService.all(languageCode);
                setReferences(Object.assign({}, references, { scholarSubject: foreignLanguages }));
                break;
            default:
                let newReferences: BaseReferencesStateInterface = {} as BaseReferencesStateInterface;

                await Promise.all([
                    DeviceService.all(languageCode).then((res) => (newReferences.device = res)),
                    PetTypeService.all(languageCode).then((res) => (newReferences.pet_type = res)),
                    ScholarLevelService.all(languageCode).then((res) => (newReferences.scholar_level = res)),
                    ScholarSubjectService.all(languageCode).then((res) => (newReferences.scholar_subject = res)),
                    SurfaceTypeService.all(languageCode).then((res) => (newReferences.surface_type = res)),
                    MovingVehicleTypeService.all(languageCode).then((res) => (newReferences.moving_vehicle_type = res)),
                    VehicleTypeService.all(languageCode).then((res) => (newReferences.vehicle_type = res)),
                    FileService.all(languageCode).then((res) => (newReferences.file = res)),
                    ReportingService.all(languageCode).then((res) => (newReferences.reporting = res)),
                    ReportingService.all(languageCode).then((res) => (newReferences.reporting = res)),
                    MobileLinkService.all(languageCode).then((res) => (newReferences.mobile_link = res)),
                    ForeignLanguages.all(languageCode).then((res) => (newReferences.foreign_languages = res)),
                ]);

                setReferences(newReferences);
                break;
        }
        // eslint-disable-next-line
    }, []);

    const refreshReference = useCallback(
        <T extends keyof getReferenceReturn>(type?: T): void => {
            fetchReferences(locale, type);
        },
        [fetchReferences, locale]
    );

    const getReference = useCallback(
        <T extends keyof getReferenceReturn>(type: T, code: string): getReferenceReturn[T] | undefined => {
            if (!references[type]) {
                return undefined;
            }
            return references[type].find((value) => {
                return code && value.code.toString() === code.toString();
            });
        },
        [references]
    );

    const allReferences = useCallback(
        <T extends keyof getReferenceReturn>(type: T): Array<getReferenceReturn[T]> => {
            return references[type];
        },
        [references]
    );

    const formatForSelect = useCallback(
        <T extends keyof getReferenceReturn>(type: T): Array<Options> => {
            return references[type]
                .filter((value: any) => !value.deleted_at)
                .map((value: any) => {
                    return {
                        label: value.value !== undefined ? value.value : value.code,
                        value: value.code,
                    };
                });
        },
        [references]
    );

    useEffect(() => {
        const initReferences = async () => {
            await fetchReferences(locale);
            setIsLoading(false);
        };
        initReferences();
    }, [fetchReferences, locale]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <ReferenceContext.Provider
            value={{
                get: getReference,
                all: allReferences,
                selectList: formatForSelect,
                refreshData: refreshReference,
            }}
        >
            {children}
        </ReferenceContext.Provider>
    );
};

export { Provider, Consumer, ReferenceContext as Context };

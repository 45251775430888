import React, { useContext, useState } from "react";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext, IntlContext, ListContext } from "context";
import dataFetcher, { BookingInterface, BookingStatus } from "dataProvider/Booking";
import { ListItemWithIds } from "components/List";
import Button from "ui/Button";
import { Role } from "dataProvider/User";
import RatingModal from "app/Booking/List/RatingModal";
import useQueryString from "helpers/useQueryString";
import RefuseServiceModal from "./RefuseBookingModal";
import ValidateBookingModal from "./ValidateBookingModal";
import ConfirmModal from "ui/Modal/ConfirmModal";
import { BookingValidationStatus } from "dataProvider/BookingValidation";
import InvoiceDataFetcher from "dataProvider/Invoice";
import fileDownload from "js-file-download";
import ImageUpload from "components/File/ImageUpload";

const List: React.FC = () => {
    const intl = useContext(IntlContext);
    const { account } = useContext(AuthContext);
    const { refreshData } = useContext(ListContext);
    const [isModalHidden, setIsModalHidden] = useState<boolean>(true);
    const [isHiddenValidateServiceModal, setIsHiddenValidateServiceModal] = useState<boolean>(true);
    const [isHiddenRefuseServiceModal, setIsHiddenRefuseServiceModal] = useState<boolean>(true);
    const [showCancelBookingModal, setShowCancelBookingModal] = useState<boolean>(false);
    const [selectedBookingId, setSelectedBookingId] = useState<string>();
    const queryString = useQueryString();
    const navigate = useNavigate();

    const renderIcon = (category: string, booking: any) => {
        // TODO uncomment line when we have all image for all category
        // const img = require(`assets/images/${category}.jpeg`);
        return <ImageUpload id={booking.provider.photo} withUpload={false} alt={category} />;
    };

    const formatDate = (date: string) => {
        const dateFormatted = moment(date);

        return dateFormatted.locale("fr").format("LL");
    };

    const handleDownload = (booking: BookingInterface) => {
        InvoiceDataFetcher.download({ booking_id: booking.id }).then((response) => {
            fileDownload(response.content, response.name);
        });
    };

    const handleDownloadSalesSuccession = (booking: BookingInterface) => {
        dataFetcher.downloadSalesSuccession({ booking_id: booking.id }).then((response) => {
            fileDownload(response.content, response.name);
        });
    };

    const handleConfirmCancelModal = async () => {
        const filterStatus = queryString.get("filter_status");
        setShowCancelBookingModal(false);

        if (
            (filterStatus === BookingStatus.PENDING || filterStatus === BookingStatus.TO_VALIDATE) &&
            selectedBookingId
        ) {
            await dataFetcher.cancel(selectedBookingId);
            refreshData();
        }

        if (filterStatus === BookingStatus.TO_VALIDATE) {
            setIsHiddenRefuseServiceModal(false);
        }
    };

    return (
        <React.Fragment>
            <ListItemWithIds
                classContainerItem={"booking_container"}
                rendererHeader={(booking: BookingInterface) => {
                    const userClient = account?.resources?.client;
                    const userProvider = account?.resources?.provider;
                    const startTimeSplit = booking.start_time.split(":")[0];
                    let role: Role | undefined = undefined;
                    let hasRated: boolean = false;

                    if (userClient && userClient[0].id === booking.client_id) {
                        role = Role.CLIENT;

                        if (booking.notes.provider) {
                            hasRated = true;
                        }
                    }

                    if (userProvider && userProvider[0].id === booking.provider_id) {
                        role = Role.PROVIDER;

                        if (booking.notes.client) {
                            hasRated = true;
                        }
                    }

                    return (
                        <React.Fragment key={`heading-${booking.id}`}>
                            <div className="booking">
                                <div className="booking_image">{renderIcon(booking.category.toString(), booking)}</div>
                                <div className="booking_content">
                                    <div>
                                        <p className="booking_content--title">
                                            {intl.formatMessage(
                                                "booking.list.services.label." +
                                                    booking.category.toString().toLowerCase()
                                            )}
                                        </p>
                                    </div>
                                    <div className="second_part">
                                        <p className="booking_content_container_date_time margin-top--3">
                                            <span className="booking_content_container_date_time--date">
                                                {formatDate(booking.date)}
                                            </span>
                                            <span className="booking_content_container_date_time--time">
                                                {intl.formatMessage("booking.list.services.label.time", {
                                                    hour_start: startTimeSplit,
                                                    hour_end: parseInt(startTimeSplit) + booking.nb_hours,
                                                    time: booking.nb_hours,
                                                })}
                                            </span>
                                        </p>
                                        {booking.client?.city && <span>{booking.client.city}</span>}
                                    </div>
                                </div>
                                <div className="booking_links">
                                    {booking.status === BookingStatus.CLOSED && !hasRated && (
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                setIsModalHidden(false);
                                                setSelectedBookingId(booking.id);
                                            }}
                                            className="btn btn__outline--primary btn--white"
                                        >
                                            {intl.formatMessage("booking.list.links.label.show.rating")}
                                        </Button>
                                    )}
                                    {queryString.get("filter_status") === BookingStatus.TO_VALIDATE &&
                                        ((role === Role.CLIENT && !booking.booking_validation.client) ||
                                            (role === Role.PROVIDER && !booking.booking_validation.provider)) && (
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    setIsHiddenValidateServiceModal(false);
                                                    setSelectedBookingId(booking.id);
                                                }}
                                                className="btn btn__outline--primary btn--white"
                                            >
                                                {intl.formatMessage("booking.list.links.label.validate.booking")}
                                            </Button>
                                        )}
                                    {queryString.get("filter_status") === BookingStatus.TO_VALIDATE &&
                                        ((role === Role.CLIENT &&
                                            booking.booking_validation?.client?.status ===
                                                BookingValidationStatus.DRAFT) ||
                                            (role === Role.PROVIDER &&
                                                booking.booking_validation?.provider?.status ===
                                                    BookingValidationStatus.DRAFT)) && (
                                            <Button
                                                type="button"
                                                onClick={() => navigate(`/mes-reservations/${booking.id}/resume`)}
                                                className="btn btn__outline--primary btn--white"
                                            >
                                                {intl.formatMessage("booking.list.links.label.pay.booking")}
                                            </Button>
                                        )}
                                    {(BookingStatus.PENDING === queryString.get("filter_status") ||
                                        BookingStatus.TO_VALIDATE === queryString.get("filter_status")) && (
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                setShowCancelBookingModal(true);
                                                setSelectedBookingId(booking.id);
                                            }}
                                            className="btn btn__outline--primary btn--white"
                                        >
                                            {role === Role.PROVIDER
                                                ? intl.formatMessage("booking.list.links.label.provider_cancel.booking")
                                                : intl.formatMessage("booking.list.links.label.cancel.booking")}
                                        </Button>
                                    )}
                                    <NavLink
                                        to={`${booking.id}/details`}
                                        className="btn btn__outline--primary btn--white"
                                    >
                                        {intl.formatMessage("booking.list.links.label.show.booking")}
                                    </NavLink>
                                    <NavLink
                                        to={`/messagerie/${booking.service_id}/${booking.client_id}/messages`}
                                        className="btn btn__outline--primary btn--white"
                                    >
                                        {intl.formatMessage("booking.list.links.label.show.access_message")}
                                    </NavLink>
                                    {booking.invoices_count > 0 ? (
                                        <Button
                                            className="btn__icon btn__icon--text"
                                            onClick={() => handleDownload(booking)}
                                        >
                                            <span className="icon icon-search"></span>
                                            <span>
                                                {booking.invoices_count > 1
                                                    ? intl.formatMessage("booking.list.links.label.download.invoices")
                                                    : intl.formatMessage("booking.list.links.label.download.invoice")}
                                            </span>
                                        </Button>
                                    ) : account?.user.currentRole === Role.PROVIDER ? (
                                        <Button
                                            className="btn__icon btn__icon--text"
                                            onClick={() => handleDownloadSalesSuccession(booking)}
                                        >
                                            <span className="icon icon-search"></span>
                                            <span>
                                                {intl.formatMessage(
                                                    "booking.list.links.label.download.sales_succession"
                                                )}
                                            </span>
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                            {booking.status === BookingStatus.CLOSED &&
                                selectedBookingId === booking.id &&
                                !hasRated && (
                                    <RatingModal
                                        isHidden={isModalHidden}
                                        setIsHidden={setIsModalHidden}
                                        booking={booking}
                                        dateFormatter={formatDate}
                                        role={role}
                                    />
                                )}
                            {selectedBookingId === booking.id && (
                                <React.Fragment>
                                    <ValidateBookingModal
                                        isHiddenValidateServiceModal={isHiddenValidateServiceModal}
                                        setIsHiddenValidateServiceModal={setIsHiddenValidateServiceModal}
                                        setIsHiddenRefuseServiceModal={setIsHiddenRefuseServiceModal}
                                        booking={booking}
                                        dateFormatter={formatDate}
                                        role={role}
                                        startTimeSplit={startTimeSplit}
                                    />
                                    <RefuseServiceModal
                                        isHiddenRefuseServiceModal={isHiddenRefuseServiceModal}
                                        setIsHiddenRefuseServiceModal={setIsHiddenRefuseServiceModal}
                                        booking={booking}
                                        dateFormatter={formatDate}
                                        role={role}
                                        startTimeSplit={startTimeSplit}
                                    />
                                </React.Fragment>
                            )}
                            {booking.id === selectedBookingId && (
                                <ConfirmModal
                                    show={showCancelBookingModal}
                                    onClose={() => setShowCancelBookingModal(false)}
                                    onConfirm={() => handleConfirmCancelModal()}
                                    title={
                                        role === Role.PROVIDER
                                            ? intl.formatMessage("booking.list.modal.provider_cancel.booking.title")
                                            : intl.formatMessage("booking.list.modal.cancel.booking.title")
                                    }
                                    message={
                                        role === Role.PROVIDER
                                            ? intl.formatMessage("booking.list.modal.provider_cancel.booking.message")
                                            : intl.formatMessage("booking.list.modal.cancel.booking.message")
                                    }
                                    confirmButton={
                                        role === Role.PROVIDER
                                            ? intl.formatMessage("booking.list.links.label.provider_cancel.booking")
                                            : intl.formatMessage("booking.list.links.label.cancel.booking")
                                    }
                                    cancelButton={intl.formatMessage("generic.action.cancel")}
                                    cancelClassName="btn btn--cancel"
                                />
                            )}
                        </React.Fragment>
                    );
                }}
            >
                {[]}
            </ListItemWithIds>
        </React.Fragment>
    );
};

export default List;

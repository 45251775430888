import React, { useContext } from "react";
import { IntlContext } from "context";
import { TextField } from "components/Form/Field";
import Label from "ui/Form/Element/Label";

const OurServicesResumeInvoice: React.FC = () => {
    const intl = useContext(IntlContext);

    return (
        <React.Fragment>
            <h3 className="margin-bottom--3">{intl.formatMessage("our_services.form.label.booking_summary")}</h3>
            <div className="grid--1 grid--medium-1 grid--small-1">
                <Label>{intl.formatMessage("our_services.form.label.details_invoice")}</Label>
                <TextField
                    inputProps={{
                        name: "rate_hour",
                        autoComplete: "rate_hour",
                        placeholder: intl.formatMessage("our_services.form.label.details_invoice.rate_hour"),
                        disabled: true,
                    }}
                    labelProps={{
                        label: intl.formatMessage("our_services.form.label.details_invoice.rate_hour"),
                    }}
                />
                <TextField
                    inputProps={{
                        name: "management_fees",
                        autoComplete: "management_fees",
                        placeholder: intl.formatMessage("our_services.form.label.details_invoice.management_fees"),
                        disabled: true,
                    }}
                    labelProps={{
                        label: intl.formatMessage("our_services.form.label.details_invoice.management_fees"),
                    }}
                />
                <TextField
                    inputProps={{
                        name: "supported_company",
                        autoComplete: "supported_company",
                        placeholder: intl.formatMessage("our_services.form.label.details_invoice.supported_company"),
                        disabled: true,
                    }}
                    labelProps={{
                        label: intl.formatMessage("our_services.form.label.details_invoice.supported_company"),
                    }}
                />
                <TextField
                    inputProps={{
                        name: "total_amount",
                        autoComplete: "total_amount",
                        placeholder: intl.formatMessage("our_services.form.label.details_invoice.total_amount"),
                        disabled: true,
                    }}
                    labelProps={{
                        label: intl.formatMessage("our_services.form.label.details_invoice.total_amount"),
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default OurServicesResumeInvoice;

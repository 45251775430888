export enum SubCategory {
    TROUBLESHOOTING = "TROUBLESHOOTING",
    REPAIR = "REPAIR",
    INTERNET_USE = "INTERNET_USE",
    ADMINISTRATIVE = "ADMINISTRATIVE",
    /*JOB_SEARCHING = "JOB_SEARCHING",*/

    KEEPING_AT_HOME = "KEEPING_AT_HOME",
    KEEPING_AT_PETSITTER = "KEEPING_AT_PETSITTER",
    WALK = "WALK",

    SIMPLE = "SIMPLE",
    FURNITURE = "FURNITURE",
    ELECTRICITY = "ELECTRICITY",
    INSTALLATION = "INSTALLATION",

    GARAGE = "GARAGE",
    HOUSE = "HOUSE",
    FLAT = "FLAT",
    SHED = "SHED",
    ATTIC = "ATTIC",

    IRONING = "IRONING",
    CLEANING = "CLEANING",
    WINDOW_CLEANING = "WINDOW_CLEANING",
    CAR_CLEANING = "CAR_CLEANING",
    LAUNDRY = "LAUNDRY",

    BABYSITTING_DURING_WEEK = "BABYSITTING_DURING_WEEK",
    BABYSITTING_DURING_WEEKEND = "BABYSITTING_DURING_WEEKEND",

    PLANTATION = "PLANTATION",
    WATERING = "WATERING",
    TERRACE = "TERRACE",
    TRIMMING = "TRIMMING",

    PRIVATE_LESSON = "PRIVATE_LESSON",
    HOMEWORK = "HOMEWORK",
    FOREIGN_LANGUAGE_COURSES = "FOREIGN_LANGUAGE_COURSES",
}

export enum LinkSubCategoryWithCategory {
    TROUBLESHOOTING = "ASSISTANCE",
    REPAIR = "ASSISTANCE",
    INTERNET_USE = "ASSISTANCE",
    ADMINISTRATIVE = "ASSISTANCE",
    JOB_SEARCHING = "ASSISTANCE",

    KEEPING_AT_HOME = "PETCARE",
    KEEPING_AT_PETSITTER = "PETCARE",
    WALK = "PETCARE",

    SIMPLE = "HANDIWORK",
    FURNITURE = "HANDIWORK",
    ELECTRICITY = "HANDIWORK",
    INSTALLATION = "HANDIWORK",

    GARAGE = "MOVING",
    HOUSE = "MOVING",
    FLAT = "MOVING",
    SHED = "MOVING",
    ATTIC = "MOVING",

    IRONING = "HOUSEKEEPING",
    CLEANING = "HOUSEKEEPING",
    WINDOW_CLEANING = "HOUSEKEEPING",
    CAR_CLEANING = "HOUSEKEEPING",
    LAUNDRY = "HOUSEKEEPING",

    BABYSITTING_DURING_WEEK = "CHILDCARE",
    BABYSITTING_DURING_WEEKEND = "CHILDCARE",

    PLANTATION = "GARDENING",
    WATERING = "GARDENING",
    TERRACE = "GARDENING",
    TRIMMING = "GARDENING",

    PRIVATE_LESSON = "TUTORING",
    HOMEWORK = "TUTORING",
    FOREIGN_LANGUAGE_COURSES = "TUTORING",
}

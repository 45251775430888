import React, { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { AuthContext, IntlContext } from "context";
import { Form } from "react-final-form";
import Button from "ui/Button";
import arrayMutators from "final-form-arrays";
import OurServicesResumeInformation from "./Informations";
import OurServicesResumeInvoice from "./Invoice";
import { BookingContext } from "../Context";
import dataFetcher from "dataProvider/BookService";
import dataFetcherProfile from "dataProvider/Profile";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import AdditionalFields from "./Fields";
import { Role } from "dataProvider/User";
import roundNumber from "helpers/roundNumber";
import usePromise from "helpers/usePromise";
import CalculOpperator from "helpers/CalculOpperator";

const OurServicesResume: React.FC = () => {
    const intl = useContext(IntlContext);
    const { account, currentRole, selectRole } = useContext(AuthContext);
    const { id } = useParams();
    const { getBooking, getDataAccount, setBooking } = useContext(BookingContext);
    const navigate = useNavigate();
    const bookingData = useMemo(() => {
        return getBooking();
    }, [getBooking]);
    const dataCompanyLimitUse = usePromise(dataFetcherProfile.companyLimit);

    const initialValues = useMemo(() => {
        let data = {
            client_first_name: account?.user.first_name,
            gender: account?.user.gender,
            client_last_name: account?.user.last_name,
            client_phone: account?.user.phone,
            client_address: account?.user.address,
            client_house_number: account?.user.house_number,
            client_street: account?.user.street,
            client_postcode: account?.user.postcode,
            client_city: account?.user.city,
            client_coordinates_lat: account?.user.coordinates.lat,
            client_coordinates_lon: account?.user.coordinates.lon,
            same_address_invoice: ["1"],
        };

        if (bookingData && dataCompanyLimitUse.data) {
            const opperator = new CalculOpperator(bookingData);
            const totalPrice = parseFloat(bookingData.price) * bookingData.nb_hours;
            let [managementFeesValue, totalAmount] = opperator.computeTTC(totalPrice);
            let supportedCompany = 0;
            let supportedCompanyPercent = 0;
            let companyLimitUse = Number(dataCompanyLimitUse.data.companyLimitUse);
            let companyLimit = Number(dataCompanyLimitUse.data.companyLimit);

            if (
                account?.resources?.client &&
                account?.resources?.client[0].company_percentage &&
                companyLimitUse < companyLimit
            ) {
                supportedCompanyPercent = parseFloat(account?.resources?.client[0].company_percentage);
                supportedCompany = totalAmount * (supportedCompanyPercent / 100);

                if (companyLimitUse + supportedCompany >= companyLimit) {
                    supportedCompany -= roundNumber(companyLimitUse + supportedCompany - companyLimit);
                }
            }

            totalAmount = totalAmount - supportedCompany;

            data = Object.assign({}, data, {
                rate_hour: totalPrice,
                management_fees: roundNumber(managementFeesValue),
                supported_company: supportedCompanyPercent,
                total_amount: roundNumber(totalAmount),
            });
        }

        return data;
    }, [
        dataCompanyLimitUse.data,
        account?.user.first_name,
        account?.user.gender,
        account?.user.last_name,
        account?.user.phone,
        account?.user.address,
        account?.user.house_number,
        account?.user.street,
        account?.user.postcode,
        account?.user.city,
        account?.user.coordinates.lat,
        account?.user.coordinates.lon,
        account?.resources?.client,
        bookingData,
    ]);

    const backToAvailability = async () => {
        const booking = getBooking();

        try {
            if (booking?.id) {
                await dataFetcher.deleteBookSlots(booking.id);
                navigate(`/nos-services/${id}/reservation/disponibilites`, {
                    state: {
                        fromNextStep: true,
                    },
                });
            }
        } catch (e) {
            // nothing
        }
    };

    const onSubmit = async (data: any) => {
        if (bookingData?.id) {
            try {
                const email = getDataAccount()?.email;
                const password = getDataAccount()?.password;

                if (email && password) {
                    data = Object.assign({}, data, { email: email, password: password });
                }

                let bookingUpdated = await dataFetcher.updateBookSlots(bookingData.id, data);
                setBooking(bookingUpdated);

                if (currentRole === Role.PROVIDER) {
                    selectRole(Role.CLIENT);
                }

                navigate(`/nos-services/${id}/reservation/paiement`);
            } catch (err: any) {
                if (err.response && err.response.data) {
                    return formatErrorResponse(err.response.data);
                }
                throw err;
            }
        }
    };

    return (
        <div className="our_services">
            <div className="container view_container">
                <Button className="btn btn--yellow btn--square margin-bottom--4" onClick={backToAvailability}>
                    {intl.formatMessage("generic.action.back")}
                </Button>
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    mutators={{ ...arrayMutators }}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="login__form">
                            <div className="our_services_view_container">
                                <div className="grid--1 grid--medium-1 grid--small-1 grid--has-gutter-3x">
                                    <div className="container_form_intermediate_page">
                                        <div className="container_form_intermediate_page padding--3">
                                            <AdditionalFields />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid--12 grid--medium-1 grid--small-1 grid--has-gutter-3x padding-top--3">
                                    <div className="container_form_intermediate_page padding--3 grid__col--6 grid__col--small-12">
                                        <OurServicesResumeInformation />
                                    </div>
                                    <div className="container_form_intermediate_page padding--3 grid__col--6 grid__col--small-12">
                                        <OurServicesResumeInvoice />
                                    </div>
                                </div>
                                <div className="flex flex--align-items-center flex--justify-content-center margin-top--2">
                                    <Button type="submit" className="btn btn--primary">
                                        {intl.formatMessage("our_services.form.action.pay")}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                />
            </div>
        </div>
    );
};

export default OurServicesResume;

import React, { useContext } from "react";
import { IntlContext, ReferenceContext } from "context";
import { SubCategory } from "dataProvider/SubCategory";
import { ServiceInterface } from "dataProvider/Service";

interface Props {
    service: ServiceInterface;
}
const Troubleshooting: React.FC<Props> = ({ service }) => {
    const intl = useContext(IntlContext);
    const reference = useContext(ReferenceContext);

    return (
        <React.Fragment>
            {[SubCategory.TROUBLESHOOTING, SubCategory.REPAIR].includes(service.subcategory) && (
                <div className="service_detail">
                    <label>{intl.formatMessage("our_services.label.device_type")}</label>
                    {service.data.device_type?.map((device_type, key) => {
                        let deviceTypeLength = service.data.device_type?.length ?? 0;
                        let translatedDevice = reference.get("device", device_type)?.value;

                        if (key + 1 < deviceTypeLength) {
                            return `${translatedDevice}, `;
                        }

                        return `${translatedDevice}`;
                    })}
                </div>
            )}
            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.${service.subcategory}`)}</label>

                {intl.formatMessage("our_services.label.effective_price", {
                    price: Number.parseFloat(service.price).toPrecision(4),
                })}
            </div>
            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.accepted_payment_type`)}</label>
                Carte bancaire, Césu {/* TODO: get payment from server */}
            </div>
        </React.Fragment>
    );
};

export default Troubleshooting;

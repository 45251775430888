import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext, IntlContext } from "context";
import Button from "ui/Button";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

const TopbarAdmin: React.FC = () => {
    const { account, logout } = useContext(AuthContext);
    const intl = useContext(IntlContext);
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    return (
        <header className={`header header--admin${openMenu ? " header--open" : ""}`}>
            <div className="container">
                <Link className="header__logo" to="/">
                    <img src="/images/logo/logo-twoghether-white.svg" alt="Logo Twoghether" />
                    <span className="visuallyhidden">{intl.formatMessage("menu.home")}</span>
                </Link>
                <div className="header__menus">
                    <ul className="header__menu center">
                        <li>
                            <NavLink to="/admin/users">{intl.formatMessage("menu.admin.users")}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/bookings">{intl.formatMessage("menu.admin.bookings")}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/reportings">{intl.formatMessage("menu.admin.reportings")}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/references">{intl.formatMessage("menu.admin.references")}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/invoices">{intl.formatMessage("menu.admin.invoices")}</NavLink>
                        </li>
                    </ul>
                    <ul className="header__right">
                        <li className="header__profil-item">
                            <Link className="header__btn-profil header__btn-profil--admin" to="/profil">
                                <Icon icon={{ prefix: "fas", iconName: "user" }} />
                                <span className="visuallyhidden">{intl.formatMessage("menu.profile")}</span>
                            </Link>

                            <ul className="header__submenu header__submenu--admin">
                                <li className="header__submenu-item">
                                    <span className="header__submenu-text">
                                        {account?.user.last_name} {account?.user.first_name}
                                    </span>
                                </li>
                                <li className="header__submenu-item">
                                    <Button className="link-text padding--0" onClick={logout}>
                                        {intl.formatMessage("authentication.login.action.logout")}
                                    </Button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Button onClick={() => setOpenMenu(!openMenu)} className="header__toggle bg--primary-base">
                    <Icon icon={{ prefix: "fas", iconName: "bars" }} />
                    <span className="visuallyhidden">{intl.formatMessage("menu.open")}</span>
                </Button>
            </div>
        </header>
    );
};

export default TopbarAdmin;

import React, { useContext } from "react";
import { IntlContext, ReferenceContext } from "context";
import { SubCategory } from "dataProvider/SubCategory";
import { ServiceInterface } from "dataProvider/Service";

interface Props {
    service: ServiceInterface;
}

const Tutoring: React.FC<Props> = ({ service }) => {
    const intl = useContext(IntlContext);
    const reference = useContext(ReferenceContext);

    return (
        <React.Fragment>
            <div className="service_detail">
                <label>{intl.formatMessage("our_services.label.tutoring_care_place")}</label>
                {/*{intl.formatMessage(`service.label.tutoring_care_place.${service.data.care_place?.toLowerCase()}`)}*/}
            </div>

            {service.subcategory === SubCategory.PRIVATE_LESSON && (
                <div className="service_detail">
                    <label>{intl.formatMessage("our_services.label.subject")}</label>
                    {service.data.subject?.map((subject, key) => {
                        let subjectLength = service.data.subject?.length ?? 0;
                        let translatedSubject = reference.get("scholar_subject", subject)?.value;

                        if (key + 1 < subjectLength) {
                            return `${translatedSubject}, `;
                        }

                        return `${translatedSubject}`;
                    })}
                </div>
            )}
            <div className="service_detail">
                <label>{intl.formatMessage("our_services.label.academic_level")}</label>
                {service.data.academic_level?.map((academic_level, key) => {
                    let academicLevelLength = service.data.academic_level?.length ?? 0;
                    let translatedAcademicLevel = reference.get("scholar_level", academic_level)?.value;

                    if (key + 1 < academicLevelLength) {
                        return `${translatedAcademicLevel}, `;
                    }

                    return `${translatedAcademicLevel}`;
                })}
            </div>

            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.${service.subcategory}`)}</label>
                {intl.formatMessage("our_services.label.effective_price", {
                    price: Number.parseFloat(service.price).toPrecision(4),
                })}
            </div>

            <div className="service_detail">
                <label>{intl.formatMessage(`our_services.label.accepted_payment_type`)}</label>
                Carte bancaire, Césu {/* TODO: get payment from server */}
            </div>
        </React.Fragment>
    );
};

export default Tutoring;

import React, { useContext, useState } from "react";
import { useForm } from "react-final-form";
import { IntlContext } from "context";
import autocomplete from "dataProvider/Autocomplete";
import { Autocomplete } from "components/Form/Field";
import { LabelProps } from "ui/Form/Element/Label";
import { FieldControlProps } from "ui/Form/Element/FieldControl";

interface Props {
    labelProps?: LabelProps & { label: React.ReactNode };
    inputProps: {
        name: string;
        latitudeName: string;
        longitudeName: string;
        houseNumber?: string;
        street?: string;
        postcode?: string;
        city?: string;
        required: boolean;
    };
    fieldControlProps?: FieldControlProps;
}

const AddressAutocomplete: React.FC<Props> = ({ labelProps, inputProps, fieldControlProps }) => {
    const intl = useContext(IntlContext);
    const { batch, change } = useForm();
    const [addresses, setAddresses] = useState<Array<any>>();

    return (
        <Autocomplete
            inputProps={Object.assign({}, inputProps, {
                placeholder: intl.formatMessage("generic.placeholder.address"),
                keySearch: "q",
                valueKeySearch: "q",
                minSearch: 3,
                dataLabel: (address: any) => address.properties.label,
                dataValue: (address: any) => address.properties.label,
                dataFetcher: (params: any) =>
                    autocomplete.address(
                        Object.assign({}, params, {
                            limit: 15,
                        })
                    ),
                getRows: (res: any) => {
                    setAddresses(res.data.features);
                    return res.data.features;
                },
                onSelect: (value: any) => {
                    if (!addresses) {
                        return;
                    }

                    const address = addresses.find((item) => item.properties.label === value);

                    if (!address) {
                        return;
                    }

                    batch(() => {
                        change(inputProps.longitudeName, address.geometry.coordinates[0]);
                        change(inputProps.latitudeName, address.geometry.coordinates[1]);
                        inputProps.houseNumber && change(inputProps.houseNumber, address.properties.housenumber);
                        inputProps.street && change(inputProps.street, address.properties.street);
                        inputProps.postcode && change(inputProps.postcode, address.properties.postcode);
                        inputProps.city && change(inputProps.city, address.properties.city);
                    });
                },
                onClearCallBack: () => {
                    batch(() => {
                        change(inputProps.longitudeName, null);
                        change(inputProps.latitudeName, null);
                    });
                },
                noResultsMessage: intl.formatMessage("generic.label.no_results"),
            })}
            labelProps={labelProps}
            fieldControlProps={fieldControlProps}
        />
    );
};

export default AddressAutocomplete;

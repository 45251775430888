import { SubCategory } from "dataProvider/SubCategory";

export class Category {
    static readonly ASSISTANCE = new Category("ASSISTANCE", [
        SubCategory.ADMINISTRATIVE,
        SubCategory.TROUBLESHOOTING,
        SubCategory.REPAIR,
        SubCategory.INTERNET_USE,
        /*SubCategory.JOB_SEARCHING,*/
    ]);
    static readonly PETCARE = new Category("PETCARE", [
        SubCategory.KEEPING_AT_HOME,
        SubCategory.KEEPING_AT_PETSITTER,
        SubCategory.WALK,
    ]);
    static readonly HANDIWORK = new Category("HANDIWORK", [
        SubCategory.SIMPLE,

        SubCategory.ELECTRICITY,
        SubCategory.INSTALLATION,
        SubCategory.FURNITURE,
    ]);
    static readonly MOVING = new Category("MOVING", [
        SubCategory.FLAT,
        SubCategory.GARAGE,
        SubCategory.ATTIC,
        SubCategory.SHED,
        SubCategory.HOUSE,
    ]);
    static readonly HOUSEKEEPING = new Category("HOUSEKEEPING", [
        SubCategory.CLEANING,
        SubCategory.CAR_CLEANING,
        SubCategory.WINDOW_CLEANING,
        SubCategory.IRONING,
        SubCategory.LAUNDRY,
    ]);
    static readonly CHILDCARE = new Category("CHILDCARE", [
        SubCategory.BABYSITTING_DURING_WEEK,
        SubCategory.BABYSITTING_DURING_WEEKEND,
    ]);
    static readonly GARDENING = new Category("GARDENING", [
        SubCategory.PLANTATION,
        SubCategory.WATERING,
        SubCategory.TRIMMING,
        SubCategory.TERRACE,
    ]);
    static readonly TUTORING = new Category("TUTORING", [
        SubCategory.HOMEWORK,
        SubCategory.PRIVATE_LESSON,
        SubCategory.FOREIGN_LANGUAGE_COURSES,
    ]);

    private constructor(private readonly key: string, public readonly values: Array<String>) {}

    toString() {
        return this.key;
    }
}

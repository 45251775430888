import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { AuthContext, IntlContext } from "context";
import Button from "ui/Button";
import { BookingContext } from "../Context";
import dataFetcher from "dataProvider/BookService";
import OurServicesPaymentFormField from "./Form";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Loader } from "ui/Loader";
import CalculOpperator from "helpers/CalculOpperator";

const stripePromise =
    process.env.REACT_APP_STRIPE_API_KEY_PUBLIC && loadStripe(process.env.REACT_APP_STRIPE_API_KEY_PUBLIC);

const OurServicesPayment: React.FC = () => {
    const intl = useContext(IntlContext);
    const { account } = useContext(AuthContext);
    const { getBooking } = useContext(BookingContext);
    const { id } = useParams();
    const [clientSecret, setClientSecret] = useState<string>("");
    const [paymentIntent, setPaymentIntent] = useState<string>("");
    const navigate = useNavigate();
    const bookingData = useMemo(() => {
        return getBooking();
    }, [getBooking]);

    const backToAvailability = async () => {
        try {
            if (bookingData?.id) {
                await dataFetcher.deleteBookSlots(bookingData.id);
                navigate(`/nos-services/${id}/reservation/disponibilites`, {
                    state: {
                        fromNextStep: true,
                    },
                });
            }
        } catch (e) {
            // nothing
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (bookingData?.id && bookingData) {
                const opperateur = new CalculOpperator(null);
                const [, totalPrice] = opperateur.computeTTC(parseFloat(bookingData.price) * bookingData.nb_hours);

                const paymentIntent = await dataFetcher.createPayment({
                    id: bookingData?.id,
                    data: {
                        amount: totalPrice.toString(),
                    },
                });
                setPaymentIntent(paymentIntent.id);
                setClientSecret(paymentIntent.client_secret);
            }
        };

        fetchData();
    }, [account?.resources?.client, bookingData]);

    const options = {
        clientSecret: clientSecret,
        apparence: "stripe",
    };

    return (
        <div className="our_services">
            <div className="container view_container">
                <Button className="btn btn--yellow btn--square margin-bottom--4" onClick={backToAvailability}>
                    {intl.formatMessage("generic.action.back")}
                </Button>
                {clientSecret && stripePromise ? (
                    <Elements stripe={stripePromise} options={options}>
                        <OurServicesPaymentFormField paymentIntent={paymentIntent} />
                    </Elements>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};

export default OurServicesPayment;

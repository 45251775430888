import fetch from "./fetch";

class Autocomplete {
    user(params?: HashMap<any>) {
        return fetch.get(`autocomplete/user`, { params });
    }

    company(params?: HashMap<any>) {
        return fetch.get(`autocomplete/company`, { params });
    }

    category_subcategory(params?: HashMap<any>) {
        return fetch.get(`autocomplete/category_subcategory`, { params });
    }

    address(params?: HashMap<any>) {
        return fetch.get(
            `https://api-adresse.data.gouv.fr/search/?type=housenumber`,
            {
                params,
                withCredentials: false,
            },
            true
        );
    }
}

export default new Autocomplete();

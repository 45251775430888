import React, { useContext } from "react";
import dataFetcher, { Role, UserInterface } from "dataProvider/Collaborator";
import { AuthContext, IntlContext } from "context";
import { CustomField, DatagridWithIds, DateTimeField, ListProvider, ListWrapper, TextField } from "components/List";
import ListActions from "./Actions";
import { Navigate } from "react-router";

const ComponentList: React.FC<{ role?: Role }> = ({ role }) => {
    const intl = useContext(IntlContext);
    return (
        <ListWrapper>
            <DatagridWithIds>
                <CustomField
                    key="gender"
                    source="gender"
                    label={intl.formatMessage("admin.user.label.gender")}
                    sortable={true}
                    renderer={(record: UserInterface) => {
                        return intl.formatMessage(`admin.user.label.civility.${record.gender}`);
                    }}
                />

                <TextField
                    key="last_name"
                    source="last_name"
                    label={intl.formatMessage("admin.user.label.last_name")}
                    sortable={true}
                />
                <TextField
                    key="first_name"
                    source="first_name"
                    label={intl.formatMessage("admin.user.label.first_name")}
                    sortable={true}
                />
                <TextField
                    key="email"
                    source="email"
                    label={intl.formatMessage("admin.user.label.email")}
                    sortable={true}
                />
                <CustomField
                    key="status"
                    source="status"
                    label={intl.formatMessage("admin.user.label.status")}
                    sortable={false}
                    renderer={(record: UserInterface) => {
                        let statuses = [];

                        if (record) {
                            statuses.push({
                                label: intl.formatMessage(`admin.user.label.status.${record.resources.client.status}`),
                                class: record.resources.client.status.toLowerCase().replace("_", "-"),
                            });
                        }

                        return (
                            <>
                                {statuses.map((status) => {
                                    return <div className={`status status--${status.class}`}>{status.label}</div>;
                                })}
                            </>
                        );
                    }}
                />
                <DateTimeField
                    key="created_at"
                    source="created_at"
                    label={intl.formatMessage("admin.user.label.created_at")}
                    sortable={true}
                />
                <CustomField key="actions" renderer={(source) => <ListActions source={source} />} />
            </DatagridWithIds>
        </ListWrapper>
    );
};

const CollaboratorList: React.FC<{ role?: Role }> = ({ role }) => {
    const defaultFetcherParams = {
        order: "created_at",
        direction: "desc",
        filter_role: role,
    };

    const auth = useContext(AuthContext);

    if (auth.currentRole === "company") {
        return (
            <React.Fragment>
                <ListProvider
                    dataFetcher={dataFetcher.all}
                    defaultFetcherParams={defaultFetcherParams}
                    wordingPagerTotal="crud.pager.total.users"
                >
                    <ComponentList role={role} />
                </ListProvider>
            </React.Fragment>
        );
    }

    return <Navigate to="/" />;
};

export default CollaboratorList;

import React, { useContext } from "react";
import { IntlContext } from "context";
import CheckboxField from "components/Form/Field/Checkbox";
import { SubCategory } from "dataProvider/SubCategory";
import { WateringPLace } from "dataProvider/Service";
import { BookingContext } from "app/OurService/Booking/Context";

const GardeningFields: React.FC = () => {
    const intl = useContext(IntlContext);
    const booking = useContext(BookingContext);

    if (booking.getBooking()?.subcategory === SubCategory.WATERING) {
        return (
            <div className="grid--2 grid--small-1 grid--has-gutter-5x margin-top--4">
                <CheckboxField
                    fieldControlProps={{
                        className: "field-control-checkbox field__row--inline",
                    }}
                    inputProps={{
                        name: "data.watering_place",
                        options: [
                            {
                                label: intl.formatMessage("service.label.watering_place.garden"),
                                value: WateringPLace.GARDEN,
                            },
                            {
                                label: intl.formatMessage("service.label.watering_place.indoor"),
                                value: WateringPLace.INDOOR,
                            },
                            {
                                label: intl.formatMessage("service.label.watering_place.terrace"),
                                value: WateringPLace.TERRACE,
                            },
                        ],
                    }}
                    labelProps={{
                        label: intl.formatMessage("service.label.watering_place"),
                    }}
                />
            </div>
        );
    }

    return <></>;
};

export default GardeningFields;

import React, { useContext } from "react";
import { AuthContext, IntlContext } from "context";
import { TextField, Checkbox, Radio, Autocomplete } from "components/Form/Field";
import Address from "components/Form/Address";
import { useFormState } from "react-final-form";
import { Gender } from "dataProvider/Admin/User";
import autocomplete from "dataProvider/Autocomplete";

const OurServicesResumeInformation: React.FC = () => {
    const intl = useContext(IntlContext);
    const { values } = useFormState();
    const { isLoggedIn, account } = useContext(AuthContext);

    const formatCompanyLabel = (company: any) => {
        return company.name;
    };

    return (
        <React.Fragment>
            <h3 className="margin-bottom--3">{intl.formatMessage("our_services.form.label.your_informations")}</h3>
            <div className="grid--12 grid--medium-1 grid--small-1 grid--has-gutter-3x">
                {(!isLoggedIn || account?.resources?.client === undefined) && (
                    <div className="grid__col--12">
                        <Radio
                            fieldControlProps={{
                                className: "field-control-radio field__row--inline",
                            }}
                            labelProps={{
                                label: intl.formatMessage("our_services.form.label.gender"),
                            }}
                            inputProps={{
                                name: "gender",
                                required: true,
                                options: [
                                    {
                                        value: Gender.FEMALE,
                                        label: intl.formatMessage("generic.label.gender.FEMALE"),
                                    },
                                    {
                                        value: Gender.MALE,
                                        label: intl.formatMessage("generic.label.gender.MALE"),
                                    },
                                ],
                            }}
                        />
                    </div>
                )}
                <div className="grid__col--6 grid__col--small-12">
                    <TextField
                        inputProps={{
                            name: "client_first_name",
                            autoComplete: "first_name",
                            required: true,
                            placeholder: intl.formatMessage("our_services.form.label.first_name"),
                        }}
                        labelProps={{
                            label: intl.formatMessage("our_services.form.label.first_name"),
                        }}
                    />
                </div>
                <div className="grid__col--6 grid__col--small-12">
                    <TextField
                        inputProps={{
                            name: "client_last_name",
                            autoComplete: "last_name",
                            required: true,
                            placeholder: intl.formatMessage("our_services.form.label.last_name"),
                        }}
                        labelProps={{
                            label: intl.formatMessage("our_services.form.label.last_name"),
                        }}
                    />
                </div>
                {!isLoggedIn || !account?.resources?.client ? (
                    <div className="grid__col--12">
                        <Address
                            inputProps={{
                                name: "client_address",
                                latitudeName: "client_coordinates_lat",
                                longitudeName: "client_coordinates_lon",
                                houseNumber: "client_house_number",
                                street: "client_street",
                                postcode: "client_postcode",
                                city: "client_city",
                                required: true,
                            }}
                        />
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="grid__col--12">
                            <Address
                                inputProps={{
                                    name: "client_address",
                                    latitudeName: "client_coordinates_lat",
                                    longitudeName: "client_coordinates_lon",
                                    houseNumber: "client_house_number",
                                    street: "client_street",
                                    postcode: "client_postcode",
                                    city: "client_city",
                                    required: true,
                                }}
                                labelProps={{
                                    label: intl.formatMessage("our_services.form.label.address"),
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
                <div className="grid__col--12">
                    <Checkbox
                        inputProps={{
                            name: "same_address_invoice",
                            options: [
                                {
                                    value: "1",
                                    label: intl.formatMessage("our_services.form.label.use_same_address_for_invoice"),
                                },
                            ],
                        }}
                    />
                </div>
                {(!values.same_address_invoice || !values.same_address_invoice.length) && (
                    <div className="grid__col--12">
                        <Address
                            labelProps={{
                                label: intl.formatMessage("our_services.form.label.invoice_address"),
                            }}
                            inputProps={{
                                name: "invoice_address",
                                latitudeName: "invoice_coordinates_lat",
                                longitudeName: "invoice_coordinates_lon",
                                houseNumber: "invoice_house_number",
                                street: "invoice_street",
                                postcode: "invoice_postcode",
                                city: "invoice_city",
                                required: true,
                            }}
                        />
                    </div>
                )}
                <div className="grid__col--12">
                    <TextField
                        inputProps={{
                            name: "client_phone",
                            autoComplete: "phone",
                            required: true,
                            maxLength: 10,
                            placeholder: intl.formatMessage("our_services.form.label.phone"),
                        }}
                        labelProps={{
                            label: intl.formatMessage("our_services.form.label.phone"),
                            requiredSymbole: "",
                        }}
                    />
                </div>
                {!isLoggedIn ||
                    (!account?.resources?.client && (
                        <React.Fragment>
                            <p className="h3 margin-top--2 grid__col--12">
                                {intl.formatMessage("our_services.form.label.affiliate_has_company")}
                            </p>
                            <div className="grid__col--12">
                                <Autocomplete
                                    inputProps={{
                                        name: "company_id",
                                        keySearch: "filter_name_with_valid_signed_date",
                                        dataValue: "id",
                                        dataLabel: formatCompanyLabel,
                                        dataFetcher: autocomplete.company,
                                    }}
                                    labelProps={{
                                        label: intl.formatMessage("our_services.form.label.name_company"),
                                    }}
                                />
                            </div>

                            <div className="grid__col--12">
                                <TextField
                                    inputProps={{
                                        name: "code_company",
                                        placeholder: intl.formatMessage("our_services.form.label.code_company"),
                                    }}
                                    labelProps={{
                                        label: intl.formatMessage("our_services.form.label.code_company"),
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    ))}
            </div>
        </React.Fragment>
    );
};

export default OurServicesResumeInformation;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { IntlContext, ListContext } from "context";
import { TextField, Slider, SelectCustom } from "components/Form/Field";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import Button from "ui/Button";
import { useForm } from "react-final-form";
import useQueryString from "helpers/useQueryString";
import AddressAutocomplete from "components/Form/Address";
import { Category } from "dataProvider/Category";
import { Options } from "ui/Form/SelectCustom";

interface Props {
    defaultFilters?: HashMap<any>;
}

const Filters: React.FC<Props> = ({ defaultFilters = {} }) => {
    const intl = useContext(IntlContext);
    const { data } = useContext(ListContext);
    const form = useForm();
    const queryString = useQueryString();
    const [openMoreFilter, setOpenMoreFilter] = useState<Boolean>(false);

    const handleOnClickOpenMoreFilter = () => {
        setOpenMoreFilter(!openMoreFilter);
    };

    useEffect(() => {
        if (openMoreFilter) {
            form.change("filter_price", defaultFilters.filter_price ?? undefined);
        }
    }, [defaultFilters, form, openMoreFilter]);

    const optionsSelectCustom = useMemo(() => {
        const options: Array<Options> = [];
        const listCategory = [
            Category.ASSISTANCE,
            Category.PETCARE,
            Category.HANDIWORK,
            Category.MOVING,
            Category.HOUSEKEEPING,
            Category.CHILDCARE,
            Category.GARDENING,
            Category.TUTORING,
        ];

        listCategory.forEach((category: any) => {
            options.push({
                label: intl.formatMessage("category." + category.key.toLocaleLowerCase()),
                value: "category_" + category.key,
            });

            category.values.forEach((subcategory: any) => {
                options.push({
                    parent: "category_" + category.key,
                    label: intl.formatMessage(
                        "subcategory." + category.key.toLocaleLowerCase() + "." + subcategory.toLocaleLowerCase()
                    ),
                    value: "subcategory_" + subcategory,
                });
            });
        });

        return options;
    }, [intl]);

    return (
        <React.Fragment>
            <div className="grid--12 grid--small-12">
                <div className="grid__col--4 grid__col--medium-12 grid__col--small-12 filters_margin-top">
                    <SelectCustom
                        inputProps={{
                            name: "filter_category_subcategory",
                            value: queryString.values["filter_category_subcategory"] as string,
                            className:
                                "field-control__select field-control_without_border_radius_top_bottom--right select_custom",
                            options: optionsSelectCustom,
                            placeholder: intl.formatMessage(
                                "our_services.list.filters.placeholder.category_subcategory"
                            ),
                        }}
                        sortValue={true}
                    />
                </div>
                <div className="grid__col--4 grid__col--medium-12 grid__col--small-12">
                    <div className="grid--12 grid--small-12">
                        <div className="grid__col--6 grid__col--small-12 filters_margin-top">
                            <TextField
                                inputProps={{
                                    name: "filter_date",
                                    type: "date",
                                    className: "react-date-picker--height-40",
                                    placeholder: "jj/mm/aaaa",
                                }}
                                fieldControlProps={{
                                    className:
                                        "field-control field-control-height--40 field-control_without_border_radius_top_bottom--left field-control_without_border_radius--right field-control_without_border_radius_top_bottom--right--center-element field-control_without_border_radius--left",
                                }}
                            />
                        </div>
                        <div className="grid__col--6 grid__col--small-12 filters_margin-top">
                            <TextField
                                inputProps={{
                                    name: "filter_hour",
                                    type: "time",
                                    className: "field-control__input field-control__input--time",
                                    placeholder: "--:--",
                                }}
                                fieldControlProps={{
                                    className:
                                        "field-control field-control-height--40 field-control_without_border_radius_top_bottom--left--center-element field-control_without_border_radius_top_bottom--right field-control_without_border_radius--right field-control_without_border_radius--left field-control_without_border_radius--left--center-element",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid__col--3 grid__col--medium-11 grid__col--small-12 filters_margin-top">
                    <AddressAutocomplete
                        inputProps={{
                            name: "address",
                            latitudeName: "filter_coordinates_lat",
                            longitudeName: "filter_coordinates_lon",
                            required: false,
                        }}
                        fieldControlProps={{
                            className: "field-control field-control_without_border_radius_top_bottom--left",
                        }}
                    />
                </div>
                <div className="grid__col--1 grid__col--small-12 filters_margin-top">
                    <div className="search_icon_container">
                        <Button type="submit" className="icon btn btn--primary padding--0">
                            <Icon icon={{ prefix: "fas", iconName: "magnifying-glass" }} />
                        </Button>
                    </div>
                </div>
            </div>

            <div className="flex flex--align-items-center flex--justify-content-flex-end">
                <div style={{ cursor: "pointer" }} onClick={handleOnClickOpenMoreFilter}>
                    <span className="icon icon-arrow-right" style={{ transform: "rotate(90deg)" }}></span>
                    <span className="margin-left--1 text-underline">
                        {intl.formatMessage("our_services.list.filters.label.show_more_filters")}
                    </span>
                </div>
            </div>

            {openMoreFilter && (
                <React.Fragment>
                    <div className="grid--12 margin-top--3">
                        <div className="grid__col--4 grid__col--small-12">
                            <Slider
                                inputProps={{
                                    name: "filter_price",
                                    min: data.pager?.custom?.min ?? 5,
                                    max: data.pager?.custom?.max ?? 100,
                                    step: 1,
                                    unit: "€/h",
                                }}
                                labelProps={{
                                    label: intl.formatMessage("our_services.list.filters.label.hourly_rate"),
                                }}
                            />
                        </div>
                    </div>
                    <div className="filters__btn-group text-right">
                        <Button
                            type="button"
                            color="transparent"
                            onClick={() => {
                                form.batch(() => {
                                    form.getRegisteredFields().forEach((fieldName) => {
                                        form.change(fieldName, defaultFilters.fieldName || undefined);
                                    });
                                });
                                queryString.reset();
                                form.submit();
                            }}
                        >
                            {intl.formatMessage("generic.crud.filter.reset.init")}
                        </Button>
                        <Button type="submit" color="primary">
                            {intl.formatMessage("generic.crud.filter.submit")}
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Filters;

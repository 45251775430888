import React, { useContext } from "react";
import { AuthContext, IntlContext } from "context";
import { TextView } from "ui/View";
import { Role, TypeProviderUser } from "dataProvider/User";
import Label from "ui/Form/Element/Label";
import fileDownload from "js-file-download";
import fileDataFetcher from "dataProvider/File";
import ImageUpload from "components/File/ImageUpload";
import StarRating from "components/Rating/StarRating";
import Slider from "ui/Form/Element/Slider";
import usePromise from "helpers/usePromise";
import dataFetcher from "dataProvider/Profile";
import { Loader } from "ui/Loader";
import moment from "moment";

const ProfileCommonView: React.FC<{}> = () => {
    const intl = useContext(IntlContext);
    const { account, currentRole } = useContext(AuthContext);
    const downloadFile = (event: any, id: any, fileName?: string) => {
        if (!id) {
            return;
        }

        event.preventDefault();
        fileDataFetcher.one({ id }).then((response) => {
            fileDownload(response.content, response.name);
        });
    };
    const { isLoading, data } = usePromise(dataFetcher.companyLimit);

    if (isLoading || !data) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <div className="register__group-fields">
                <div className="register__grid profile_header">
                    <div className="profile_image">
                        <div className="flex">
                            <ImageUpload id={account?.user.picture_id} />
                        </div>
                    </div>
                    {currentRole === Role.CLIENT && (
                        <div className="rating">
                            <StarRating
                                value={
                                    currentRole === Role.CLIENT
                                        ? account?.resources?.client[0].rating.average
                                        : account?.resources?.provider[0].rating.average
                                }
                                count={
                                    currentRole === Role.CLIENT
                                        ? account?.resources?.client[0].rating.count
                                        : account?.resources?.provider[0].rating.count
                                }
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="register__group-fields">
                <div className="register__grid">
                    <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextView
                            label={intl.formatMessage("profile.label.gender")}
                            value={intl.formatMessage(`generic.label.gender.${account?.user.gender}`)}
                        />
                        <TextView
                            label={intl.formatMessage("profile.label.last_name")}
                            value={account?.user?.last_name}
                        />
                        <TextView
                            label={intl.formatMessage("profile.label.first_name")}
                            value={account?.user?.first_name}
                        />
                        <TextView label={intl.formatMessage("profile.label.phone")} value={account?.user?.phone} />
                        {currentRole === Role.CLIENT && (
                            <TextView
                                label={intl.formatMessage("profile.label.birthday")}
                                value={
                                    account?.resources?.client[0].birthday === ""
                                        ? ""
                                        : moment(account?.resources?.client[0].birthday).format("DD/MM/YYYY")
                                }
                            />
                        )}

                        {currentRole === Role.PROVIDER && (
                            <TextView
                                label={intl.formatMessage("profile.label.birthday")}
                                value={moment(account?.resources?.provider[0].birthday).format("DD/MM/YYYY")}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="register__group-fields">
                <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextView label={intl.formatMessage("profile.label.address")} value={account?.user.address} />
                </div>
            </div>
            <div className="register__group-fields">
                <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                    <TextView label={intl.formatMessage("profile.label.email")} value={account?.user.email} />
                </div>
            </div>
            {currentRole === Role.CLIENT && (
                <div className="register__group-fields">
                    <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextView
                            label={intl.formatMessage("profile.label.has_pet")}
                            value={
                                account?.resources?.client[0].has_pet
                                    ? intl.formatMessage("generic.label.yes")
                                    : intl.formatMessage("generic.label.no")
                            }
                        />
                        {account?.resources?.client[0].services && (
                            <TextView
                                label={intl.formatMessage("profile.label.services")}
                                value={
                                    Array.isArray(account?.resources?.client[0].services)
                                        ? account?.resources?.client[0].services.map((service: any) => {
                                              return intl.formatMessage("category." + service.toLowerCase());
                                          })
                                        : []
                                }
                            />
                        )}
                        <TextView
                            label={intl.formatMessage("profile.label.is_attach_company")}
                            value={
                                account?.resources?.client[0].company_id
                                    ? intl.formatMessage("generic.label.yes")
                                    : intl.formatMessage("generic.label.no")
                            }
                        />
                    </div>
                    {account?.resources?.client[0].company_id ? (
                        <React.Fragment>
                            <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                                <TextView
                                    label={intl.formatMessage("profile.label.name_company")}
                                    value={account?.resources?.client[0].company_name}
                                />
                                <TextView
                                    label={intl.formatMessage("profile.label.code_company")}
                                    value={account?.resources?.client[0].company_code}
                                />
                            </div>
                            <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                                <Slider
                                    name="ceiling_company"
                                    min={0}
                                    max={Number(data.companyLimit)}
                                    value={Number(data.companyLimitUse)}
                                    unit={"€"}
                                    label={{
                                        name: "profile.label.money_left",
                                        props: {
                                            money: Number(data.companyLimit) - Number(data.companyLimitUse),
                                        },
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    ) : null}
                    <div className="grid--1 grid--small-1 grid--has-gutter-3x register__grid">
                        <TextView
                            label={intl.formatMessage("profile.label.other_informations")}
                            value={account?.resources?.client[0].comment || ""}
                        />
                    </div>
                </div>
            )}

            {currentRole === Role.PROVIDER && (
                <React.Fragment>
                    <div className="register__group-fields">
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <TextView
                                label={intl.formatMessage("profile.label.has_job")}
                                value={
                                    account?.resources?.provider[0].has_job === "1"
                                        ? intl.formatMessage("profile.label.has_job.in_activity")
                                        : intl.formatMessage("profile.label.has_job.other")
                                }
                            />
                        </div>
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <div>
                                <Label>{intl.formatMessage("profile.label.kbis")}</Label>
                                <button
                                    className="btn btn--primary btn--link btn--link--download"
                                    onClick={(event) => downloadFile(event, account?.resources?.provider[0].kbis_id)}
                                >
                                    {intl.formatMessage("generic.label.view")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="register__group-fields">
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <TextView
                                label={intl.formatMessage("profile.label.account_name")}
                                value={account?.resources?.provider[0].account_name}
                            />
                            <TextView
                                label={intl.formatMessage("profile.label.iban")}
                                value={account?.resources?.provider[0].iban}
                            />
                        </div>
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <TextView
                                label={intl.formatMessage("profile.label.bic")}
                                value={account?.resources?.provider[0].bic}
                            />
                        </div>
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <TextView
                                label={intl.formatMessage("profile.label.type")}
                                value={
                                    account?.resources?.provider[0].type
                                        ? intl.formatMessage(
                                              "profile.label.type." + account?.resources?.provider[0].type
                                          )
                                        : ""
                                }
                            />
                            <TextView
                                label={intl.formatMessage("profile.label.siret")}
                                value={account?.resources?.provider[0].siret}
                            />
                        </div>
                        {account?.resources?.provider[0].type === TypeProviderUser.COMPANY && (
                            <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                                <TextView
                                    label={intl.formatMessage("profile.label.legal_status")}
                                    value={account?.resources?.provider[0].legal_status}
                                />
                                <TextView
                                    label={intl.formatMessage("profile.label.social_capital")}
                                    value={account?.resources?.provider[0].social_capital}
                                />
                            </div>
                        )}
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <TextView
                                label={intl.formatMessage("profile.label.has_tva")}
                                value={
                                    account?.resources &&
                                    account?.resources?.provider[0] &&
                                    account.resources.provider[0].has_tva === "1"
                                        ? intl.formatMessage("generic.label.yes")
                                        : intl.formatMessage("generic.label.no")
                                }
                            />
                            <TextView
                                label={intl.formatMessage("profile.label.tva_number")}
                                value={account?.resources?.provider[0].tva_number}
                            />
                        </div>
                        <div className="grid--2 grid--small-1 grid--has-gutter-3x register__grid">
                            <TextView
                                label={intl.formatMessage("profile.label.tva_rate")}
                                value={account?.resources?.provider[0].tva_rate}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default ProfileCommonView;
